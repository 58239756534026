import {ReactElement, cloneElement, useContext, useEffect, useState} from 'react';
import {Alert, Card, CardActionArea, CardContent, CircularProgress, IconProps, Snackbar, Stack, Typography} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {Masonry} from '@mui/lab';
import {Link} from 'react-router-dom';
import {BrokenImage, BugReport, Euro, Group, HowToVote, Lock, PersonAdd, PersonOff, PersonSearch, RecordVoiceOver, VoiceOverOff, Warning} from '@mui/icons-material';
import {ZULL_API} from 'zull-common-js';
import {GlobalContext} from '../helpers/globalContext';
import {RefreshContext} from '../helpers/refreshContext';

type TDashboardData = {
  newAccounts7days: number,
  activeAccounts7days: number,
  onlineAccountsNow: number,
  totalAccounts: number,
  bans7days: number,
  mutes7days: number,
  numberOpenTickets: number,
  income30days: number,
  votes7days: number,
  errors7days: number,
  new500errors: number,
  adminAuthAttempts7days: number
}

const DashboardCard = (props: {
  main: number | string,
  sub: string,
  linktarget: string,
  linkstate?: object,
  icon: ReactElement<IconProps>
}) => (
  <Link to={props.linktarget} state={props.linkstate}
    style={{
      textDecoration: 'none',
      textTransform: 'uppercase'
    }}>
    <Card>
      <CardActionArea>
        <CardContent>
          <Stack direction="column">
            <Typography variant="h3" align="right">
              {cloneElement(props.icon, {
                fontSize: 'large',
                sx: {float: 'left'}
              })}
              {props.main}
            </Typography>
            <Typography color="secondary" align="right" textTransform="uppercase">
              {props.sub}
            </Typography>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  </Link>
);

const PageDashboard = () => {
  const {globalState} = useContext(GlobalContext);
  const {setRefreshHandler} = useContext(RefreshContext);
  const [refreshSuccess, setRefreshSuccess] = useState(false);
  const [refreshError, setRefreshError] = useState(false);
  const [data, setData] = useState<TDashboardData | null>(null);

  const getData = () => {
    ZULL_API.GET({
      endpoint: 'admin/dashboard',
      authUser: globalState.username,
      authPass: globalState.password
    }).then(res => {
      if (!res || !res.ok || !res.body) {
        console.error(res);
        setRefreshError(true);
        return;
      }
      const ret = JSON.parse(res.body) as TDashboardData;
      // catch nulls and set to 0
      (Object.keys(ret) as (keyof TDashboardData)[]).forEach(v => {
        ret[v] = ret[v] ?? 0;
      });
      setData(ret);
      setRefreshSuccess(true);
    });
  };

  useEffect(() => {
    getData();
    setRefreshHandler(() => getData);
    return () => setRefreshHandler(null);
  }, []);

  if (data === null) return (
    <CircularProgress />
  );

  return (
    <Grid2 container spacing={2}>
      <Snackbar open={refreshSuccess} autoHideDuration={1000} onClose={() => setRefreshSuccess(false)}>
        <Alert onClose={() => setRefreshSuccess(false)}
          severity="success"
          variant="filled"
          sx={{width: '100%'}}>
          Refresh complete.
        </Alert>
      </Snackbar>
      <Snackbar open={refreshError} autoHideDuration={1000} onClose={() => setRefreshError(false)}>
        <Alert onClose={() => setRefreshError(false)}
          severity="error"
          variant="filled"
          sx={{width: '100%'}}>
          Refresh failed.
        </Alert>
      </Snackbar>

      <Grid2 xs={12}>
        <Masonry columns={{xs: 1, md: 3}} spacing={{xs: 2, md: 4}}>
          <Stack direction="column" className="section" spacing={2}>
            <DashboardCard main={data.activeAccounts7days} sub="accounts active in last 7 days" linktarget="/Statistics/Accounts"
              icon={<RecordVoiceOver color="primary" />} />
            <DashboardCard main={data.newAccounts7days} sub="new accounts in last 7 days"
              linktarget="/Users" linkstate={{pattern: 'new'}}
              icon={<PersonAdd color="primary" />} />
            <DashboardCard main={data.onlineAccountsNow} sub="accounts online now" linktarget="/Users"
              icon={<PersonSearch color="primary" />} />
            <DashboardCard main={data.totalAccounts} sub="total accounts" linktarget="/Users"
              icon={<Group color="primary" />} />
          </Stack>

          <Stack direction="column" className="section" spacing={2}>
            <DashboardCard main={`${data.income30days} €`} sub="in last 30 days" linktarget="/Statistics/Donations"
              icon={<Euro color="success" />} />
            <DashboardCard main={data.votes7days} sub="total votes in last 7 days" linktarget="/Statistics/Votes"
              icon={<HowToVote color="success" />} />
          </Stack>

          <Stack direction="column" className="section" spacing={2}>
            <DashboardCard main={data.numberOpenTickets} sub="open ingame tickets" linktarget="/Tickets"
              icon={<BugReport color={data.numberOpenTickets !== 0 ? 'warning' : 'secondary'} />} />
            <DashboardCard main={data.errors7days} sub="API errors in last 7 days" linktarget="/Logs/Errors"
              icon={<Warning color={data.errors7days !== 0 ? 'warning' : 'secondary'} />} />
            <DashboardCard main={data.new500errors} sub="new 500 errors" linktarget="/Logs/Errors"
              icon={<BrokenImage color={data.new500errors !== 0 ? 'error' : 'secondary'} />} />
            <DashboardCard main={data.adminAuthAttempts7days} sub="failed admin auths in last 7 days" linktarget="/Logs/Admin-Auth"
              icon={<Lock color={data.adminAuthAttempts7days !== 0 ? 'error' : 'secondary'} />} />
          </Stack>

          <Stack direction="column" className="section" spacing={2}>
            <DashboardCard main={data.mutes7days} sub="mutes in last 7 days" linktarget="/Users/Restricted/Mutes"
              icon={<VoiceOverOff color={data.mutes7days !== 0 ? 'warning' : 'secondary'} />} />
            <DashboardCard main={data.bans7days} sub="bans in last 7 days" linktarget="/Users/Restricted/Bans"
              icon={<PersonOff color={data.mutes7days !== 0 ? 'warning' : 'secondary'} />} />
          </Stack>
        </Masonry>
      </Grid2>
    </Grid2>
  );
};

export default PageDashboard;
