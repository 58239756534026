import {JSXElementConstructor, ReactElement, useState} from 'react';
import {Box, Tab, Tabs} from '@mui/material';

interface ITabbedProps {
  tabs: {
    /** The box to show */
    e: ReactElement<any, string | JSXElementConstructor<any>>,
    n: string,
    i?: ReactElement<any, string | JSXElementConstructor<any>>
  }[],
  defaultTab?: number
}

const Tabbed = (props: ITabbedProps) => {
  const [tab, setTab] = useState(props.defaultTab ?? 0);

  return (
    <div className="tabs">
      <Tabs value={tab} onChange={(e, v) => setTab(v)}
        variant="scrollable" scrollButtons="auto">
        {props.tabs.map(x => (<Tab key={`tab-${x.n}`} label={x.n} icon={x.i} />))}
      </Tabs>

      {props.tabs.map((x, i) => (
        <Box key={`tabcontent-${x.n}${i}`} hidden={tab !== i}>
          {x.e}
        </Box>
      ))}
    </div>
  );
};

export default Tabbed;
