import {useContext, useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {Alert, Box, Card, CircularProgress, Snackbar, Stack, Typography} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {DataGrid, GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import {ZULL_API, ZULL_DATETIME} from 'zull-common-js';
import {GlobalContext} from '../helpers/globalContext';
import Tabbed from '../components/Tabbed';
import {RefreshContext} from '../helpers/refreshContext';

type TRestrictedData = {
  numBans: number,
  numBansActive: number,
  numMutes: number,
  numLockedNames: number,
  bans: {
    id: number,
    bandate: Date | number,
    unbandate: Date | number,
    bannedby: string,
    banreason: string,
    active: boolean,
    username: string
  }[],
  mutes: {
    guid: number,
    mutedate: Date | number,
    mutetime: number,
    mutedby: string,
    mutereason: string,
    username: string
  }[],
  names: string[]
}



const columnsBans: GridColDef[] = [
  {
    field: 'active', headerName: 'BAN', type: 'boolean', width: 50,
    renderCell: (p: GridRenderCellParams<TRestrictedData['bans'][0], boolean>) =>
      (<div style={{aspectRatio: '1/1', height: '0.75em', borderRadius: '50%', background: p.value ? 'green' : 'darkred'}} />)
  },
  {
    field: 'id', headerName: 'User', type: 'number', width: 120,
    renderCell: (p: GridRenderCellParams<TRestrictedData['bans'][0], boolean>) => (
      <Link to={`/Users/Account/${p.row.id}`}>{p.row.username ?? p.value}</Link>
    )
  },
  {field: 'bandate', headerName: 'Bandate', type: 'dateTime', width: 180},
  {field: 'unbandate', headerName: 'Unbandate', type: 'dateTime', width: 180},
  {field: 'bannedby', headerName: 'Banned by', type: 'string', width: 120},
  {field: 'banreason', headerName: 'Reason', type: 'string', width: 180}
];

const columnsMutes: GridColDef[] = [
  {
    field: 'active', headerName: 'MUT', type: 'boolean', width: 50,
    renderCell: (p: GridRenderCellParams<TRestrictedData['mutes'][0], boolean>) =>
    (<div style={{
      aspectRatio: '1/1', height: '0.75em', borderRadius: '50%',
      background: (new Date()) < (new Date((p.row.mutedate as Date).getTime() + (p.row.mutetime * 60000))) ? 'green' : 'darkred'
    }} />)
  },
  {
    field: 'guid', headerName: 'User', type: 'number', width: 120,
    renderCell: (p: GridRenderCellParams<TRestrictedData['mutes'][0], boolean>) => (
      <Link to={`/Users/Account/${p.row.guid}`}>{p.row.username ?? p.value}</Link>
    )
  },
  {field: 'mutedate', headerName: 'Mutedate', type: 'dateTime', width: 180},
  {
    field: 'mutetime', headerName: 'Mutetime', type: 'string', width: 180,
    renderCell: (p: GridRenderCellParams<TRestrictedData['mutes'][0], boolean>) => (ZULL_DATETIME.timeFormat(p.row.mutetime * 60, ZULL_DATETIME.TimeFormat.HHmmssD))
  },
  {field: 'mutedby', headerName: 'Muted by', type: 'string', width: 120},
  {field: 'mutereason', headerName: 'Reason', type: 'string', width: 180}
];

const PageUsersRestricted = () => {
  const {tab} = useParams();
  const {globalState} = useContext(GlobalContext);
  const {setRefreshHandler} = useContext(RefreshContext);

  const [refreshSuccess, setRefreshSuccess] = useState(false);
  const [refreshError, setRefreshError] = useState(false);
  const [data, setData] = useState<TRestrictedData | null>(null);



  const getData = () => {
    ZULL_API.GET({
      endpoint: 'admin/user/restricted',
      authUser: globalState.username,
      authPass: globalState.password
    }).then(res => {
      if (!res || !res.ok || !res.body) {
        console.error(res);
        setRefreshError(true);
        return;
      }
      const ret: TRestrictedData = JSON.parse(res.body) as TRestrictedData;
      for (let i = 0; i < ret.bans.length; i++) {
        ret.bans[i].bandate = new Date(ret.bans[i].bandate as number * 1000);
        ret.bans[i].unbandate = new Date(ret.bans[i].unbandate as number * 1000);
      }
      for (let i = 0; i < ret.mutes.length; i++) {
        ret.mutes[i].mutedate = new Date(ret.mutes[i].mutedate as number * 1000);
      }
      setData(ret);
      setRefreshSuccess(true);
    });
  };

  useEffect(() => {
    getData();
    setRefreshHandler(() => getData);
    return () => setRefreshHandler(null);
  }, []);

  if (data === null) return (
    <CircularProgress />
  );

  return (
    <Box>
      <Snackbar open={refreshSuccess} autoHideDuration={1000} onClose={() => setRefreshSuccess(false)}>
        <Alert onClose={() => setRefreshSuccess(false)}
          severity="success"
          variant="filled"
          sx={{width: '100%'}}>
          Refresh complete.
        </Alert>
      </Snackbar>
      <Snackbar open={refreshError} autoHideDuration={1000} onClose={() => setRefreshError(false)}>
        <Alert onClose={() => setRefreshError(false)}
          severity="error"
          variant="filled"
          sx={{width: '100%'}}>
          Refresh failed.
        </Alert>
      </Snackbar>

      <Grid2 container spacing={2}>
        <Grid2 xs={8} md={2}><Typography variant="h3" align="right">{data.numBans}</Typography></Grid2>
        <Grid2 xs={4} md={1}>TOTAL BANS</Grid2>
        <Grid2 xs={8} md={2}><Typography variant="h3" align="right">{data.numBansActive}</Typography></Grid2>
        <Grid2 xs={4} md={1}>ACTIVE BANS</Grid2>
        <Grid2 xs={8} md={2}><Typography variant="h3" align="right">{data.numMutes}</Typography></Grid2>
        <Grid2 xs={4} md={1}>TOTAL MUTES</Grid2>
        <Grid2 xs={8} md={2}><Typography variant="h3" align="right">{data.numLockedNames}</Typography></Grid2>
        <Grid2 xs={4} md={1}>LOCKED NAMES</Grid2>
      </Grid2>

      <br />
      <br />

      <Tabbed defaultTab={
        tab ?
          tab === 'Bans' ? 0
            : tab === 'Mutes' ? 1
              : tab === 'Names' ? 2
                : undefined : undefined}
        tabs={[
          {
            n: 'Bans', e: (
              <Box className="section datagrid-container">
                <DataGrid getRowHeight={() => 'auto'} pageSizeOptions={[30, 50, 100]} rows={data.bans}
                  initialState={{pagination: {paginationModel: {pageSize: 100}}, sorting: {sortModel: [{field: 'bandate', sort: 'desc'}]}}}
                  columns={columnsBans} getRowId={row => `${row.id}${(row.bandate as Date).toISOString()}`} />
              </Box>
            )
          },
          {
            n: 'Mutes', e: (
              <Box className="section datagrid-container">
                <DataGrid getRowHeight={() => 'auto'} pageSizeOptions={[30, 50, 100]} rows={data.mutes}
                  initialState={{pagination: {paginationModel: {pageSize: 100}}, sorting: {sortModel: [{field: 'mutedate', sort: 'desc'}]}}}
                  columns={columnsMutes} getRowId={row => `${row.guid}${(row.mutedate as Date).toISOString()}`} />
              </Box>
            )
          },
          {
            n: 'Names', e: (
              <Box className="section" sx={{height: 'unset'}}>
                <Stack direction="row" spacing={2} useFlexGap
                  sx={{flexWrap: 'wrap'}}>
                  {data.names.map(x => (
                    <Card key={x} sx={{padding: 1}}>
                      <Typography className="rainbow-text">{x}</Typography>
                    </Card>
                  ))}
                </Stack>
              </Box>
            )
          }
        ]} />
    </Box>
  );
};

export default PageUsersRestricted;
