import {Dispatch, ReactNode, SetStateAction, createContext, useMemo, useState} from 'react';

export interface IGlobalState {
  isLoggedIn: boolean,
  username: string,
  password: string
}

export const defaultState: IGlobalState = {
  isLoggedIn: false,
  username: '',
  password: '',
};

export const GlobalContext = createContext<{globalState: IGlobalState, setGlobalState: Dispatch<SetStateAction<IGlobalState>>}>({globalState: defaultState, setGlobalState: () => {}});

const GlobalContextProvider = (props: {children: ReactNode}) => {
  const [globalState, setGlobalState] = useState(defaultState);
  const providerValue = useMemo(() =>
  ({
    globalState,
    setGlobalState
  }), [globalState, setGlobalState]);

  return (
    <GlobalContext.Provider value={providerValue}>
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
