import {CartesianGrid, ResponsiveContainer, LineChart as ReLineChart, XAxis, Label, YAxis, Line} from 'recharts';
import {initialOpacaity, animationDelayMS} from './ChartCommon';

const CustomizedLabel = (p: {x: number, y: number, stroke?: string, value: number, decimals?: boolean}) => (
  <text x={p.x} y={p.y} dy={-6}
    fill="#616161" fontSize={16} textAnchor="middle">
    {p.value > 0 ? (p.decimals ? p.value.toFixed(2) : p.value.toFixed(0)) : ''}
  </text>
);

interface ILineChartProps {
  data: {data: number, key: number | string}[],
  height: number,
  animationIndex: number,
  xLabel?: string,
  title?: string,
  minWidth?: number | string,
  decimals?: boolean,
  sumLabel?: string
}

const LineChart = (props: ILineChartProps) => {
  const sum = props.data.reduce((n, x) => n + x.data, 0);

  return (
    <ResponsiveContainer width="100%" height={props.height} minWidth={props.minWidth}>
      <ReLineChart data={props.data} title={props.title} margin={{left: -18, bottom: 32, right: 24}}>
        <text x="50%" y={20} fill="#616161"
          textAnchor="middle" dominantBaseline="central">
          <tspan fontSize={24}>{props.title}</tspan>
        </text>
        <CartesianGrid strokeDasharray="3 3" stroke="#222222" />
        <XAxis dataKey="key">
          <Label position="bottom">{props.xLabel}</Label>
          <Label style={{display: props.sumLabel ? undefined : 'none'}} position="top">{`${props.sumLabel}: ${sum}`}</Label>
        </XAxis>
        <YAxis padding={{top: 24}} dataKey="data" allowDecimals={false} />

        <Line dataKey="data" type="monotone" fillOpacity={initialOpacaity}
          label={(p: {x: number, y: number, stroke?: string, value: number}) => CustomizedLabel({x: p.x, y: p.y, stroke: p.stroke, value: p.value, decimals: props.decimals})}
          animationBegin={animationDelayMS * props.animationIndex} strokeWidth={2} />

      </ReLineChart>
    </ResponsiveContainer>
  );
};

export default LineChart;
