import {Component, JSXElementConstructor, ReactElement} from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, Modal, TextField, Typography} from '@mui/material';
import {Badge, Face3, Gavel, Group, Password, SwitchAccount, ThumbUp, Check, Close, MoneyOff, Receipt, WrongLocation, DoNotStep, Message, Email, MarkEmailUnread, AttachEmail, AcUnit, SevereCold, VolumeOff, VolumeUp, Delete, Unsubscribe, MilitaryTech} from '@mui/icons-material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {ZULL_API} from 'zull-common-js';
import {GlobalContext, IGlobalState} from '../helpers/globalContext';

export enum Commands {
  ACCOUNT_SET_PASSWORD,
  ACCOUNT_BAN_ACCOUNT,
  ACCOUNT_BAN_IP,
  ACCOUNT_UNBAN,

  CHARACTER_CHANGEACCOUNT,
  CHARACTER_CHANGEFACTION,
  CHARACTER_CHANGERACE,
  CHARACTER_RENAME,

  CHARACTER_ITEMRESTORE,
  CHARACTER_ITEMRESTORE_LIST,
  CHARACTER_UNSTUCK,
  CHARACTER_KICK,

  CHARACTER_SENDMESSAGE,
  CHARACTER_SENDMAIL,
  CHARACTER_SENDMONEY,
  CHARACTER_SENDITEMS,

  CHARACTER_FREEZE,
  CHARACTER_UNFREEZE,
  CHARACTER_MUTE,
  CHARACTER_UNMUTE,

  GUILD_DELETE,
  GUILD_INVITE,
  GUILD_RANK,
  GUILD_RENAME,
  GUILD_UNINVITE,
}

export const CommandData: {[key in Commands]: {
  cmd: string,
  name: string,
  syn: string,
  severity: 0 | 1 | 2,
  icon: ReactElement<any, string | JSXElementConstructor<any>>,
  keepLines?: boolean
}} = {
  [Commands.ACCOUNT_SET_PASSWORD]: {cmd: 'account set password', name: 'Set Password', syn: '[AccountName] [newPass] [newPass (repeat)]', severity: 0, icon: <Password />},
  [Commands.ACCOUNT_BAN_ACCOUNT]: {cmd: 'ban account', name: 'Ban Account', syn: '[AccountName] [Bantime (-1 perma, 4d20h3d)] [Reason]', severity: 2, icon: <Gavel />},
  [Commands.ACCOUNT_BAN_IP]: {cmd: 'ban ip', name: 'Ban IP', syn: '[IP] [Bantime (-1 perma, 4d20h3d)] [Reason]', severity: 2, icon: <Gavel />},
  [Commands.ACCOUNT_UNBAN]: {cmd: 'unban account', name: 'Unban Account', syn: '[AccountName]', severity: 1, icon: <ThumbUp />},

  [Commands.CHARACTER_CHANGEACCOUNT]: {cmd: 'character changeaccount', name: 'Change Account', syn: '[NewAccountName] [Character]', severity: 0, icon: <SwitchAccount />},
  [Commands.CHARACTER_CHANGEFACTION]: {cmd: 'character changefaction', name: 'Change Faction', syn: '[Character]', severity: 0, icon: <Group />},
  [Commands.CHARACTER_CHANGERACE]: {cmd: 'character changerace', name: 'Change Race', syn: '[Character]', severity: 0, icon: <Face3 />},
  [Commands.CHARACTER_RENAME]: {cmd: 'character rename', name: 'Rename', syn: '[Character] [Lock]', severity: 1, icon: <Badge />},

  [Commands.CHARACTER_ITEMRESTORE]: {cmd: 'item restore', name: 'Restore Item', syn: '[recoveryItemID (check List Restore)] [Character]', severity: 0, icon: <MoneyOff />},
  [Commands.CHARACTER_ITEMRESTORE_LIST]: {cmd: 'item restore list', name: 'List Restore Items', syn: '[Character] [PageNumber]', severity: 0, icon: <Receipt />, keepLines: true},
  [Commands.CHARACTER_UNSTUCK]: {cmd: 'unstuck', name: 'Unstuck', syn: '[Character]', severity: 0, icon: <WrongLocation />},
  [Commands.CHARACTER_KICK]: {cmd: 'kick', name: 'Kick', syn: '[Character] [Reason (optional)]', severity: 1, icon: <DoNotStep />},

  [Commands.CHARACTER_SENDMESSAGE]: {cmd: 'send message', name: 'Send Message', syn: '[Character] [Message]', severity: 0, icon: <Message />},
  [Commands.CHARACTER_SENDMAIL]: {cmd: 'send mail', name: 'Send Mail', syn: '[Character] "[Subject]" "[Text]" (Quotes are neccessary!)', severity: 0, icon: <Email />},
  [Commands.CHARACTER_SENDMONEY]: {cmd: 'send money', name: 'Send Money', syn: '[Character] "[Subject]" "[Text]" [moneyAsCopper] (Quotes are neccessary!)', severity: 0, icon: <MarkEmailUnread />},
  [Commands.CHARACTER_SENDITEMS]: {cmd: 'send items', name: 'Send Items', syn: '[Character] "[Subject]" "[Text]" [itemid1[:count]] [itemid2[:count]] (Quotes are neccessary, item counts are optional)', severity: 0, icon: <AttachEmail />},

  [Commands.CHARACTER_FREEZE]: {cmd: 'freeze', name: 'Freeze', syn: '[Character]', severity: 1, icon: <AcUnit />},
  [Commands.CHARACTER_UNFREEZE]: {cmd: 'unfreeze', name: 'Unfreeze', syn: '[Character]', severity: 1, icon: <SevereCold />},
  [Commands.CHARACTER_MUTE]: {cmd: 'mute', name: 'Mute', syn: '[Character] [timeInMinutes] [Reason]', severity: 1, icon: <VolumeOff />},
  [Commands.CHARACTER_UNMUTE]: {cmd: 'unmute', name: 'Unmute', syn: '[Character]', severity: 1, icon: <VolumeUp />},

  [Commands.GUILD_DELETE]: {cmd: 'guild delete', name: 'Delete', syn: '[LeaderCharName] "[GuildName]" (Quotes are neccessary!)', severity: 2, icon: <Delete />},
  [Commands.GUILD_INVITE]: {cmd: 'guild invite', name: 'Invite', syn: '[Character] "[GuildName]" (Quotes are neccessary!)', severity: 0, icon: <Email />},
  [Commands.GUILD_RANK]: {cmd: 'guild rank', name: 'Rank', syn: '[Character] [RankID]', severity: 0, icon: <MilitaryTech />},
  [Commands.GUILD_RENAME]: {cmd: 'guild rename', name: 'Rename', syn: '"[GuildName]" "[NewName]" (Quotes are neccessary!)', severity: 1, icon: <Badge />},
  [Commands.GUILD_UNINVITE]: {cmd: 'guild uninvite', name: 'Uninvite', syn: '[Character]', severity: 0, icon: <Unsubscribe />},
};


export const CommandButton = (props: {cmd: Commands, onClick: () => void, disabled?: boolean}) => (
  <Button variant="contained" className="gridbutton"
    color={CommandData[props.cmd].severity === 0 ? 'primary' : CommandData[props.cmd].severity === 1 ? 'warning' : 'error'}
    startIcon={CommandData[props.cmd].icon} disabled={props.disabled}
    onClick={props.onClick}>
    {CommandData[props.cmd].name}
  </Button>
);


interface ICommandModalProps {
  onDone: () => void,
  globalState: IGlobalState
}
interface ICommandModalState {
  isOpen: boolean,
  command: Commands | null,
  args: string,
  argsLocked: boolean,
  alertOpen: boolean,
  alertCont: string,
  error: boolean,
  isLoading: boolean
}
class CommandModal extends Component<ICommandModalProps, ICommandModalState> {
  constructor(props: ICommandModalProps) {
    super(props);
    this.state = {isOpen: false, command: null, args: '', argsLocked: false, alertOpen: false, alertCont: '', error: false, isLoading: false};
  }

  public open = () => {
    this.setState(state => ({...state, isOpen: true}));
  };
  public setCommand = (cmd: Commands) => {
    this.setState(state => ({...state, command: cmd}));
  };
  public setArgs = (args: string) => {
    this.setState(state => ({...state, args: args}));
  };
  public setLocked = () => {
    this.setState(state => ({...state, argsLocked: true}));
  };

  private handleClose = () => {
    this.setState(state => ({...state, isOpen: false, error: false, alertOpen: false, argsLocked: false, isLoading: false}));
    this.props.onDone();
  };
  private runCommand = () => {
    if (this.state.command === null) return;
    this.setState(state => ({...state, isLoading: true}));
    console.log(`Running '${CommandData[this.state.command].cmd} ${this.state.args}`);
    ZULL_API.POST({
      endpoint: 'admin/command',
      authUser: this.props.globalState.username,
      authPass: this.props.globalState.password,
      body: JSON.stringify({command: CommandData[this.state.command].cmd.trim(), args: this.state.args.trim()})
    }).then(res => {
      if (!res.ok || !res.body) console.error(res);
      this.setState(state => ({...state, error: !res.ok, alertOpen: true, alertCont: res.body ?? 'NO CONTENT', isLoading: false}));
    });
  };

  render() {
    if (this.state.command === null) return <div />;
    return (
      <>
        <Dialog open={this.state.alertOpen} onClose={this.handleClose}>
          <DialogTitle>Result</DialogTitle>
          <DialogContent sx={{overflowX: CommandData[this.state.command].keepLines ? 'auto' : undefined}}>
            <DialogContentText color={this.state.error ? 'red' : undefined} sx={{whiteSpace: CommandData[this.state.command].keepLines ? 'pre' : undefined}}>
              {this.state.alertCont}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>OK</Button>
          </DialogActions>
        </Dialog>



        <Modal open={this.state.isOpen} onClose={this.handleClose}>
          <Box sx={{
            position: 'absolute',
            top: '50%',
            width: {xs: '100vw', md: '60vw'},
            left: {xs: 0, md: '50%'},
            transform: {xs: 'translateY(-50%)', md: 'translate(-50%, -50%)'},
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}>
            <Typography variant="h5">{CommandData[this.state.command].icon}&nbsp;&nbsp;&nbsp;{CommandData[this.state.command].name}</Typography>
            <br />
            <Grid2 container spacing={2}>
              <Grid2 xs={12}><code>.{CommandData[this.state.command].cmd}</code></Grid2>
              <Grid2 xs={12} />

              <Grid2 xs={12}><LinearProgress style={{display: this.state.isLoading ? 'block' : 'none'}} /></Grid2>
              <Grid2 xs={12}><Typography>Arguments:</Typography></Grid2>
              <Grid2 xs={12} md={12}><TextField size="small" fullWidth disabled={this.state.argsLocked}
                label={CommandData[this.state.command].syn} value={this.state.args}
                onChange={e => this.setState(state => ({...state, args: e.target.value}))}
                onKeyUp={e => {if (e.key === 'Enter') this.runCommand();}} /></Grid2>

              <Grid2 xs={2} md={1}><Button variant="contained" className="gridbutton" onClick={this.handleClose}
                color="secondary">
                <Close />
              </Button></Grid2>
              <Grid2 xs={6} md={9} />
              <Grid2 xs={4} md={2}>
                <Button variant="contained" className="gridbutton" onClick={this.runCommand}
                  startIcon={<Check />} disabled={this.state.isLoading}>
                  GO
                </Button>
              </Grid2>
            </Grid2>
          </Box>
        </Modal>

      </>
    );
  }
}

CommandModal.contextType = GlobalContext;
export default CommandModal;
