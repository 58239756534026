import {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Box, Snackbar, Alert} from '@mui/material';
import {Delete} from '@mui/icons-material';
import {DataGrid, GridActionsCellItem, GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import {ZULL_API, ZULL_DATETIME} from 'zull-common-js';
import {GlobalContext} from '../helpers/globalContext';
import {RefreshContext} from '../helpers/refreshContext';

type TTicket = {
  id: number,
  playerGuid: number,
  name: string,
  createTime: number | Date,
  lastModifiedTime: number | Date,
  description: string,
  comment: string,
  response: string
}

const PageTickets = () => {
  const {globalState} = useContext(GlobalContext);
  const {setRefreshHandler} = useContext(RefreshContext);
  const [refreshSuccess, setRefreshSuccess] = useState(false);
  const [refreshError, setRefreshError] = useState(false);
  const [data, setData] = useState<TTicket[]>([]);

  const getData = () => {
    ZULL_API.GET({
      endpoint: 'admin/ticket',
      authUser: globalState.username,
      authPass: globalState.password
    }).then(res => {
      if (!res || !res.ok || !res.body) {
        console.error(res);
        setRefreshError(true);
        return;
      }
      const ret: TTicket[] = JSON.parse(res.body) as TTicket[];
      ret.forEach((x, i) => {
        ret[i].createTime = new Date(x.createTime as number * 1000);
        ret[i].lastModifiedTime = new Date(x.lastModifiedTime as number * 1000);
      });
      setData(ret);
      setRefreshSuccess(true);
    });
  };

  const closeTicket = (id: number) => {
    ZULL_API.POST({
      endpoint: 'admin/command',
      authUser: globalState.username,
      authPass: globalState.password,
      body: JSON.stringify({command: 'ticket close', args: id})
    }).then(res => {
      if (!res || !res.ok) {
        console.error(res);
        setRefreshError(true);
        return;
      }
      return ZULL_API.POST({
        endpoint: 'admin/command',
        authUser: globalState.username,
        authPass: globalState.password,
        body: JSON.stringify({command: 'ticket delete', args: id})
      });
    }).then(res => {
      if (!res || !res.ok) {
        console.error(res);
        setRefreshError(true);
        return;
      }
      getData();
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'actions', type: 'actions', width: 50, getActions: (p) => [
        <GridActionsCellItem icon={<Delete />}
          label="Close & Delete"
          onClick={() => closeTicket(p.row.id)} />
      ]
    },
    {field: 'id', headerName: 'ID', type: 'number', width: 80},
    {
      field: 'name', headerName: 'Char', type: 'string', width: 120,
      renderCell: (p: GridRenderCellParams<TTicket, string>) => (
        <Link to={`/Users/Character/${p.row.playerGuid}`}>
          {p.row.name}
        </Link>
      )
    },
    {
      field: 'createTime', headerName: 'Age', type: 'string', width: 260,
      valueFormatter: (value: Date) =>
        `${value.toDateString()} (${ZULL_DATETIME.timeFormat(((new Date()).getTime() - value.getTime()) / 1000, ZULL_DATETIME.TimeFormat.HHmmssD)})`
    },
    {field: 'description', headerName: 'Content', type: 'string', width: 700}
  ];

  useEffect(() => {
    getData();
    setRefreshHandler(() => getData);
    return () => setRefreshHandler(null);
  }, []);

  return (
    <Box>
      <Snackbar open={refreshSuccess} autoHideDuration={1000} onClose={() => setRefreshSuccess(false)}>
        <Alert onClose={() => setRefreshSuccess(false)}
          severity="success"
          variant="filled"
          sx={{width: '100%'}}>
          Refresh complete.
        </Alert>
      </Snackbar>
      <Snackbar open={refreshError} autoHideDuration={1000} onClose={() => setRefreshError(false)}>
        <Alert onClose={() => setRefreshError(false)}
          severity="error"
          variant="filled"
          sx={{width: '100%'}}>
          Refresh failed.
        </Alert>
      </Snackbar>



      <Box className="section datagrid-container">
        <DataGrid getRowHeight={() => 'auto'} pageSizeOptions={[30, 50, 100]} rows={data}
          initialState={{pagination: {paginationModel: {pageSize: 100}}, sorting: {sortModel: [{field: 'id', sort: 'asc'}]}}}
          columns={columns} />
      </Box>
    </Box>
  );
};

export default PageTickets;
