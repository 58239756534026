import {ResponsiveContainer, BarChart as ReBarChart, CartesianGrid, Label, XAxis, Bar, Cell} from 'recharts';
import {useNavigate} from 'react-router-dom';
import {animationDelayMS, initialOpacaity} from './ChartCommon';

const CustomizedLabel = (p: {x: number, y: number, width: number, stroke?: string, value: number, decimals?: boolean, unit?: string}) => (
  <text x={p.x - 4} y={p.y + 18}
    fill="#ccc" fontSize={16} textAnchor="end"
    transform={`rotate(-90,${p.x},${p.y})`} >
    {p.value > 0 ? (p.decimals ? p.value.toFixed(2) : p.value.toFixed(0)) + (p.unit ? ` ${p.unit}` : '') : ''}
  </text>
);
const CustomizedTick = (p: {x: number, y: number, payload: {value: string}}) => (
  <text x={p.x + 16} y={p.y + 16} fill="#fff"
    textAnchor="start" transform={`rotate(-90,${p.x},${p.y})`}>
    {p.payload.value}
  </text>
);

interface IBarChartProps {
  data: {data: number, key: number | string, link?: string}[],
  height: number,
  animationIndex: number,
  title?: string,
  minWidth?: number | string,
  decimals?: boolean,
  sumLabel?: string,
  unit?: string
}

const BarChart = (props: IBarChartProps) => {
  const navigate = useNavigate();
  const sum = props.data.reduce((n, x) => n + x.data, 0);

  return (
    <ResponsiveContainer width="100%" height={props.height} minWidth={props.minWidth}>
      <ReBarChart data={props.data} title={props.title} margin={{left: 8, right: 8, top: 16, bottom: props.sumLabel ? 24 : 0}}>
        <text x="50%" y={20} fill="#616161"
          textAnchor="middle" dominantBaseline="central">
          <tspan fontSize={24}>{props.title}</tspan>
        </text>
        <CartesianGrid strokeDasharray="3 3" stroke="#222222" />
        <XAxis dataKey="key" interval={0}
          tick={(p: {x: number, y: number, payload: {value: string}}) => CustomizedTick({x: p.x, y: p.y, payload: p.payload})}>
          <Label style={{display: props.sumLabel ? undefined : 'none', textTransform: 'uppercase'}} position="insideBottomLeft" dy={16}
            fontSize={18} fill="#fff">
            {`${props.sumLabel}: ${sum}${props.unit ? ` ${props.unit}` : ''}`}
          </Label>
        </XAxis>

        <Bar dataKey="data" type="monotone" fillOpacity={initialOpacaity}
          label={(p: {x: number, y: number, width: number, stroke?: string, value: number}) => CustomizedLabel({x: p.x, y: p.y, width: p.width, stroke: p.stroke, value: p.value, decimals: props.decimals, unit: props.unit})}
          minPointSize={1} animationBegin={animationDelayMS * props.animationIndex}>
          {props.data.map(x => (
            <Cell fill="#3081bc" key={x.key} opacity={initialOpacaity}
              style={{cursor: x.link ? 'pointer' : undefined}}
              onClick={x.link ? () => navigate(x.link ?? '/') : undefined} />
          ))}
        </Bar>
      </ReBarChart>
    </ResponsiveContainer>
  );
};

export default BarChart;
