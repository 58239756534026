import {useContext, useEffect, useRef, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputAdornment, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {DataGrid, GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import {ZULL_API, ZULL_DATETIME, ZULL_STRING} from 'zull-common-js';
import {GlobalContext} from '../helpers/globalContext';
import Tabbed from '../components/Tabbed';
import CommandModal, {CommandButton, Commands} from '../components/CommandModal';
import {RefreshContext} from '../helpers/refreshContext';

type TCharacterEntry = {
  guid: number,
  name: string,
  race: string,
  class: string,
  level: number,
  zone: string,
  online: boolean,
  guildid: number | null,
  guildname: string | null
}
type TAccountData = {
  id: number,
  username: string,
  email: string,
  joindate: string,
  last_ip: string,
  last_login: string,
  online: boolean,
  totaltime: number,
  vp: number,
  dp: number,
  total_votes: number,
  total_bonuses: number,
  nickname: string,

  mutetime: Date | number,
  mutereason: string,
  muteby: string,
  mutehistory: {
    mutedate: Date | number,
    mutetime: number,
    mutedby: string,
    mutereason: string
  }[] | null,
  baninfo: {
    bandate: Date | number,
    unbandate: Date | number,
    bannedby: string,
    banreason: string
  } | null,
  banhistory: {
    bandate: Date | number,
    unbandate: Date | number,
    bannedby: string,
    banreason: string
  }[] | null,
  characters: TCharacterEntry[]
}

const columnsCharacters: GridColDef[] = [
  {
    field: 'online', headerName: 'ON', type: 'boolean', width: 50,
    renderCell: (p: GridRenderCellParams<TCharacterEntry, boolean>) =>
      (<div style={{aspectRatio: '1/1', height: '0.75em', borderRadius: '50%', background: p.value ? 'green' : 'darkred'}} />)
  },
  {field: 'guid', headerName: 'GUID', type: 'number', width: 80},
  {
    field: 'name', headerName: 'Name', type: 'string', width: 120,
    renderCell: (p: GridRenderCellParams<TCharacterEntry, string>) => (
      <Link to={`/Users/Character/${p.row.guid}`}>{p.value}</Link>
    )
  },
  {field: 'race', headerName: 'Race', type: 'string', width: 100},
  {field: 'class', headerName: 'Class', type: 'string', width: 120},
  {field: 'level', headerName: 'Lvl', type: 'number', width: 60},
  {field: 'zone', headerName: 'Zone', type: 'string', width: 240},
  {
    field: 'guildname', headerName: 'Guild', type: 'string', width: 180,
    renderCell: (p: GridRenderCellParams<TCharacterEntry, string>) => (<Link to={`/Users/Guild/${p.row.guildid}`}>{p.value}</Link>)
  }
];

const PageUsersAccount = () => {
  const {id} = useParams();
  const {globalState} = useContext(GlobalContext);
  const {setRefreshHandler} = useContext(RefreshContext);
  const commandModal = useRef<CommandModal | null>(null);

  const [refreshSuccess, setRefreshSuccess] = useState(false);
  const [refreshError, setRefreshError] = useState(false);
  const [data, setData] = useState<TAccountData | null>(null);
  const [originalData, setOriginalData] = useState<TAccountData | null>(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState<{msg: string, onCommit: () => void}>({msg: '', onCommit: () => {}});

  const getData = () => {
    if (!id) return;
    ZULL_API.POST({
      endpoint: 'admin/user/accountdata',
      authUser: globalState.username,
      authPass: globalState.password,
      body: JSON.stringify({id: parseInt(id)})
    }).then(res => {
      if (!res || !res.ok || !res.body) {
        console.error(res);
        setRefreshError(true);
        return;
      }
      const ret: TAccountData = JSON.parse(res.body) as TAccountData;
      ret.mutetime = new Date(ret.mutetime as number * 1000);
      if (ret.mutehistory) {
        for (let i = 0; i < ret.mutehistory.length; i++) {
          ret.mutehistory[i].mutedate = new Date(ret.mutehistory[i].mutedate as number * 1000);
        }
      }
      if (ret.baninfo) {
        ret.baninfo.bandate = new Date(ret.baninfo.bandate as number * 1000);
        ret.baninfo.unbandate = new Date(ret.baninfo.unbandate as number * 1000);
      }
      if (ret.banhistory) {
        for (let i = 0; i < ret.banhistory.length; i++) {
          ret.banhistory[i].bandate = new Date(ret.banhistory[i].bandate as number * 1000);
          ret.banhistory[i].unbandate = new Date(ret.banhistory[i].unbandate as number * 1000);
        }
      }
      setData(ret);
      setOriginalData(ret);
      setRefreshSuccess(true);
    });
  };



  const handleAlertClose = () => {
    setAlertOpen(false);
    setAlertData({msg: '', onCommit: () => {}});
  };
  const setNewData = (prop: string, newData: string | number) => {
    console.log(`Set ${prop} to ${newData} on ${data?.id}`);
    ZULL_API.POST({
      endpoint: 'admin/user/setaccountdata',
      authUser: globalState.username,
      authPass: globalState.password,
      body: JSON.stringify({id: data?.id, datapoint: prop, data: typeof newData === 'string' ? newData.trim() : newData})
    }).then(res => {
      if (!res || !res.ok) {
        console.error(res);
        setRefreshError(true);
      }
      getData();
    });
  };



  const handleCommand = (cmd: Commands, args: string, lock?: boolean) => {
    if (!commandModal.current) return;
    commandModal.current.setCommand(cmd);
    commandModal.current.setArgs(args);
    if (lock) commandModal.current.setLocked();
    commandModal.current.open();
  };



  useEffect(() => {
    getData();
    setRefreshHandler(() => getData);
    return () => setRefreshHandler(null);
  }, []);

  if (data === null) return (
    <CircularProgress />
  );

  return (
    <Box>
      <Snackbar open={refreshSuccess} autoHideDuration={1000} onClose={() => setRefreshSuccess(false)}>
        <Alert onClose={() => setRefreshSuccess(false)}
          severity="success"
          variant="filled"
          sx={{width: '100%'}}>
          Refresh complete.
        </Alert>
      </Snackbar>
      <Snackbar open={refreshError} autoHideDuration={1000} onClose={() => setRefreshError(false)}>
        <Alert onClose={() => setRefreshError(false)}
          severity="error"
          variant="filled"
          sx={{width: '100%'}}>
          Refresh failed.
        </Alert>
      </Snackbar>


      <Dialog open={alertOpen} onClose={() => handleAlertClose()}>
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent><DialogContentText>{alertData.msg}</DialogContentText></DialogContent>
        <DialogActions>
          <Button onClick={() => {handleAlertClose(); setData(originalData);}}>Cancel</Button>
          <Button onClick={() => {alertData.onCommit(); handleAlertClose();}}>Yes</Button>
        </DialogActions>
      </Dialog>

      <h2 className="section">
        <div style={{display: 'inline-block', aspectRatio: '1/1', height: '0.75em', borderRadius: '50%', marginRight: '0.5em', background: data.online ? 'green' : 'darkred'}} />
        Account {data.username}
      </h2>
      <Box className="section">
        <Grid2 spacing={2} container>
          <Grid2 xs={12} md={4}><TextField size="small" fullWidth disabled
            label="Username" value={data.username} /></Grid2>
          <Grid2 xs={12} md={4}><TextField size="small" fullWidth
            label="Email" value={data.email} onChange={e => setData({...data, email: e.target.value})}
            onBlur={() => {
              if (data.email === originalData?.email) return;
              setAlertData({msg: `Are you SURE you want to change the email on account ${data.id} "${data.username}" to ${data.email} ?`, onCommit: () => setNewData('email', data.email)});
              setAlertOpen(true);
            }}
            onKeyUp={e => {
              if (e.key !== 'Enter' || data.email === originalData?.email) return;
              (e.target as HTMLInputElement).blur();
            }} /></Grid2>
          <Grid2 xs={12} md={4}><TextField size="small" fullWidth disabled
            label="last_ip" value={data.last_ip} /></Grid2>

          <Grid2 xs={12} md={4}><TextField size="small" fullWidth disabled
            label="Joined" value={data.joindate} /></Grid2>
          <Grid2 xs={12} md={4}><TextField size="small" fullWidth disabled
            label="Last Online" value={data.last_login} /></Grid2>
          <Grid2 xs={12} md={4}><TextField size="small" fullWidth disabled
            label="Total Time" value={ZULL_DATETIME.timeFormat(data.totaltime, ZULL_DATETIME.TimeFormat.HHmmssD)} /></Grid2>

          <Grid2 xs={12} />
          <Grid2 xs={12} md={4}><TextField size="small" fullWidth
            label="DP" value={data.dp} onChange={e => setData({...data, dp: parseInt(e.target.value)})}
            onBlur={() => {
              if (data.dp === originalData?.dp) return;
              setAlertData({msg: `Are you SURE you want to change the DP amount on account ${data.id} "${data.username}" to ${data.dp} DP?`, onCommit: () => setNewData('dp', data.dp)});
              setAlertOpen(true);
            }}
            onKeyUp={e => {
              if (e.key !== 'Enter' || data.dp === originalData?.dp) return;
              (e.target as HTMLInputElement).blur();
            }} /></Grid2>
          <Grid2 xs={12} md={4}><TextField size="small" fullWidth disabled
            label="Total Donates" value={data.total_bonuses} InputProps={{endAdornment: <InputAdornment position="end">€</InputAdornment>}} /></Grid2>
          <Grid2 xs={0} md={4} sx={{display: {xs: 'none', sm: 'none', md: 'block'}}} />

          <Grid2 xs={12} md={4}><TextField size="small" fullWidth
            label="VP" value={data.vp} onChange={e => setData({...data, vp: parseInt(e.target.value)})}
            onBlur={() => {
              console.log('blur');
              if (data.vp === originalData?.vp) return;
              setAlertData({msg: `Are you SURE you want to change the VP amount on account ${data.id} "${data.username}" to ${data.vp} VP?`, onCommit: () => setNewData('vp', data.vp)});
              setAlertOpen(true);
            }}
            onKeyUp={e => {
              if (e.key !== 'Enter' || data.vp === originalData?.vp) return;
              console.log(e);
              (e.target as HTMLInputElement).blur();
            }} /></Grid2>
          <Grid2 xs={12} md={4}><TextField size="small" fullWidth disabled
            label="Total Votes" value={data.total_votes} /></Grid2>
          <Grid2 xs={0} md={4} sx={{display: {xs: 'none', sm: 'none', md: 'block'}}} />
        </Grid2>
      </Box>


      <Box className="section yellow" hidden={data.mutehistory === null}>
        <Grid2 container spacing={2} sx={{display: (data.mutetime as Date) < (new Date()) ? 'none' : undefined}}>
          <Grid2 xs={12}><h4>Active mute:</h4></Grid2>
          <Grid2 xs={12} md={4}><TextField size="small" fullWidth disabled
            label="Muted until" value={(data.mutetime as Date).toISOString().replace('T', ' ').replace('.000Z', '')} /></Grid2>
          <Grid2 xs={12} md={4}><TextField size="small" fullWidth disabled
            label="Muted by" value={data.muteby} /></Grid2>
          <Grid2 xs={12} md={4}><TextField size="small" fullWidth disabled
            label="Reason" value={data.mutereason} /></Grid2>
          <Grid2 xs={12} />
          <Grid2 xs={12} />
        </Grid2>
        <h4>History:</h4>
        <TableContainer component={Paper}>
          <Table sx={{minWidth: 560}} size="small">
            <TableHead>
              <TableRow>
                <TableCell>Mutedate</TableCell>
                <TableCell align="right">Mute Duration</TableCell>
                <TableCell align="right">Muted by</TableCell>
                <TableCell align="right">Reason</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.mutehistory?.map((x, i) => (
                <TableRow key={i}>
                  <TableCell>{(x.mutedate as Date).toISOString().replace('T', ' ').replace('.000Z', '')}</TableCell>
                  <TableCell align="right">{ZULL_DATETIME.timeFormat(x.mutetime * 60, ZULL_DATETIME.TimeFormat.HHmmssD)}</TableCell>
                  <TableCell align="right">{x.mutedby}</TableCell>
                  <TableCell align="right">{x.mutereason}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>


      <Box className="section red" hidden={data.baninfo === null && data.banhistory === null}>
        <Grid2 container spacing={2} sx={data.baninfo === null ? {display: 'none'} : undefined}>
          <Grid2 xs={12}><h4>Active:</h4></Grid2>
          <Grid2 xs={12} md={6}><TextField size="small" fullWidth disabled
            label="Bandate" value={data.baninfo ? (data.baninfo?.bandate as Date).toISOString().replace('T', ' ').replace('.000Z', '') : ''} /></Grid2>
          <Grid2 xs={12} md={6}><TextField size="small" fullWidth disabled
            label="Unbandate"
            value={data.baninfo ? data.baninfo?.bandate < data.baninfo?.unbandate ? (data.baninfo?.unbandate as Date).toISOString().replace('T', ' ').replace('.000Z', '') : 'PERMANENT' : ''} /></Grid2>
          <Grid2 xs={12} md={6}><TextField size="small" fullWidth disabled
            label="Banned by" value={data.baninfo?.bannedby} /></Grid2>
          <Grid2 xs={12} md={6}><TextField size="small" fullWidth disabled
            label="Reason" value={data.baninfo?.banreason} /></Grid2>
        </Grid2>
        {data.baninfo !== null && <br />}
        <h4>History:</h4>
        <TableContainer component={Paper}>
          <Table sx={{minWidth: 560}} size="small">
            <TableHead>
              <TableRow>
                <TableCell>Bandate</TableCell>
                <TableCell align="right">Reason</TableCell>
                <TableCell align="right">Banned by</TableCell>
                <TableCell align="right">Unbandate</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.banhistory?.map((x, i) => (
                <TableRow key={i}>
                  <TableCell>{(x.bandate as Date).toISOString().replace('T', ' ').replace('.000Z', '')}</TableCell>
                  <TableCell align="right">{x.banreason}</TableCell>
                  <TableCell align="right">{x.bannedby}</TableCell>
                  <TableCell align="right">{x.bandate < x.unbandate ? (x.unbandate as Date).toISOString().replace('T', ' ').replace('.000Z', '') : 'PERMANENT'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>


      <Tabbed tabs={[
        {
          n: 'Characters', e: (
            <Box className="section datagrid-container-small">
              <DataGrid getRowHeight={() => 'auto'} pageSizeOptions={[30, 50, 100]} rows={data.characters}
                initialState={{pagination: {paginationModel: {pageSize: 100}}, sorting: {sortModel: [{field: 'guid', sort: 'asc'}]}}}
                columns={columnsCharacters} getRowId={row => row.guid} />
            </Box>
          )
        },
        {
          n: 'Actions', e: (
            <Box className="section">
              <Grid2 container spacing={2}>
                <Grid2 xs={12} md={3}>
                  <CommandButton cmd={Commands.ACCOUNT_SET_PASSWORD} onClick={() => {
                    const rnd = ZULL_STRING.randomString(8);
                    handleCommand(Commands.ACCOUNT_SET_PASSWORD, `${data.username} ${rnd} ${rnd}`);
                  }} />
                </Grid2>
                <Grid2 xs={12} md={3}><CommandButton cmd={Commands.ACCOUNT_BAN_ACCOUNT} disabled={data.baninfo !== null}
                  onClick={() => handleCommand(Commands.ACCOUNT_BAN_ACCOUNT, `${data.username} -1 Reason`)} /></Grid2>
                <Grid2 xs={12} md={3}><CommandButton cmd={Commands.ACCOUNT_BAN_IP}
                  onClick={() => handleCommand(Commands.ACCOUNT_BAN_IP, `${data.last_ip} 1d Ban`, true)} /></Grid2>
                <Grid2 xs={12} md={3}><CommandButton cmd={Commands.ACCOUNT_UNBAN} disabled={data.baninfo === null}
                  onClick={() => handleCommand(Commands.ACCOUNT_UNBAN, `${data.username}`, true)} /></Grid2>
              </Grid2>
            </Box>
          )
        }
      ]} />

      <CommandModal onDone={() => getData()} ref={commandModal} globalState={globalState} />
    </Box>
  );
};

export default PageUsersAccount;
