import {ReactElement, JSXElementConstructor, useState, useContext} from 'react';
import {Link as RouterLink, Outlet, useLocation} from 'react-router-dom';
import {Box, CSSObject, Collapse, Divider, Fab, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Theme, Toolbar, Typography, styled, useMediaQuery, useTheme} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {Refresh} from '@mui/icons-material';
import NotificationHandler from './components/NotificationHandler';
import {RefreshContext} from './helpers/refreshContext';

export type TRoute = {
  name: string,
  icon: ReactElement<any, any>,
  element: ReactElement<any, string | JSXElementConstructor<any>> | null,
  subRoutes?: TRoute[],
  param?: string
};



/* /////////////////////////////////////////////////////////////////////////// */



const drawerWidth = 280;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {width: `calc(${theme.spacing(8)} + 1px)`, },
});

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
  ({theme, open}) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);



interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {shouldForwardProp: (prop) => prop !== 'open', })<AppBarProps>(({theme, open}) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));



/* /////////////////////////////////////////////////////////////////////////// */



const NavWrapper = (props: {routes: TRoute[]}) => {
  const route = useLocation();
  const theme = useTheme();
  const breakpointLarge = useMediaQuery(theme.breakpoints.up('md'));
  const [open, setOpen] = useState(breakpointLarge);
  const {refreshHandler} = useContext(RefreshContext);

  return (
    <Box sx={{display: 'flex'}}>
      <AppBar position="fixed" open={open} elevation={2}>
        <Toolbar>
          <IconButton color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(true)}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && {display: 'none'}),
            }}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {route.pathname.substring(route.pathname.lastIndexOf('/') + 1)}
          </Typography>
          {refreshHandler !== null &&
            <Fab size="medium" color="primary" sx={{marginLeft: 'auto', border: 1}}
              onClick={refreshHandler}>
              <Refresh />
            </Fab>
          }
        </Toolbar>
      </AppBar>


      <Drawer variant="permanent" open={open} PaperProps={{elevation: 1}}>
        <DrawerHeader>
          <NotificationHandler style={{marginRight: 'auto'}} />
          <IconButton onClick={() => setOpen(false)}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {props.routes.map(x => (
            <ListItem key={x.name} disablePadding sx={{display: 'block'}}>
              <RouterLink className="button-link" to={x.element === null && x.subRoutes ? `/${x.name}/${x.subRoutes[0].name}` : `/${x.name}`}>
                <ListItemButton sx={{minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5}}
                  selected={route.pathname === `/${x.name}` || route.pathname.includes(`/${x.name}/`)}
                  onClick={() => {if (route.pathname === `/${x.name}` && refreshHandler) refreshHandler();}} >
                  <ListItemIcon sx={{minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center'}}>
                    {x.icon}
                  </ListItemIcon>
                  <ListItemText primary={x.name} sx={{opacity: open ? 1 : 0}} />
                  {x.subRoutes ? <ExpandMore /> : null}
                </ListItemButton>
              </RouterLink>
              {
                x.subRoutes !== undefined ?
                  <Collapse in={route.pathname.includes(`${x.name}`)} timeout="auto">
                    <List disablePadding>
                      {x.subRoutes.map(y => (
                        <ListItem key={`${x.name}-${y.name}`} disablePadding sx={{display: 'block', pl: open ? 2 : undefined}}>
                          <RouterLink className="button-link" to={`/${x.name}/${y.name}`}>
                            <ListItemButton sx={{minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5}}
                              selected={route.pathname.includes(`/${x.name}/${y.name}`)}
                              onClick={() => {if (route.pathname.includes(`/${x.name}/${y.name}`) && refreshHandler) refreshHandler();}}>
                              <ListItemIcon sx={{minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center'}}>
                                {y.icon}
                              </ListItemIcon>
                              <ListItemText primary={y.name} sx={{opacity: open ? 1 : 0}} />
                            </ListItemButton>
                          </RouterLink>
                        </ListItem>
                      ))}
                    </List>
                    <Divider sx={{marginTop: 1}} />
                  </Collapse>
                  : null
              }
            </ListItem>
          ))}
        </List>
      </Drawer>


      <Box component="main" sx={{
        flexGrow: 1,
        p: 2,
        maxWidth: open ? `calc(100vw - ${drawerWidth}px)` : theme.breakpoints.up('sm') ?
          `calc(100vw - ${theme.spacing(8)} + 1px)` :
          `calc(100vw - ${theme.spacing(7)} + 1px)`
      }}>
        <DrawerHeader />
        <Outlet />

        {/* Padding to compensate for browser bars on mobile */}
        <br />
        <br />
        <br />
      </Box>
    </Box >
  );
};

export default NavWrapper;
