import {useContext, useEffect, useState} from 'react';
import {Alert, Box, Slider, Snackbar, Typography} from '@mui/material';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {ZULL_API} from 'zull-common-js';
import {GlobalContext} from '../helpers/globalContext';
import {RefreshContext} from '../helpers/refreshContext';

type TRowAPIError = {
  date: Date | string,
  to: string,
  subject: string,
  link: string | null
}

const columns: GridColDef[] = [
  {field: 'date', headerName: 'Date', type: 'dateTime', width: 180},
  {field: 'to', headerName: 'Recipient', type: 'string', width: 240},
  {field: 'subject', headerName: 'Subject', type: 'string', width: 280},
  {field: 'link', headerName: 'Link', type: 'string', width: 460}
];

const PageLogsMails = () => {
  const {globalState} = useContext(GlobalContext);
  const {setRefreshHandler} = useContext(RefreshContext);
  const [refreshSuccess, setRefreshSuccess] = useState(false);
  const [refreshError, setRefreshError] = useState(false);
  const [days, setDays] = useState(7);
  const [totalCount, setTotalCount] = useState(0);
  const [rows, setRows] = useState<(TRowAPIError & {id: number})[]>([]);

  const getData = (daysToGet?: number) => {
    if (daysToGet === undefined) daysToGet = days;
    ZULL_API.POST({
      endpoint: 'admin/log/mails',
      authUser: globalState.username,
      authPass: globalState.password,
      body: JSON.stringify({days: daysToGet})
    }).then(res => {
      if (!res || !res.ok || !res.body) {
        console.error(res);
        setRefreshError(true);
        return;
      }
      const ret = JSON.parse(res.body) as TRowAPIError[];
      const fin: (TRowAPIError & {id: number})[] = [];
      for (let i = 0; i < ret.length; i++) fin.push({...ret[i], id: i, date: new Date(ret[i].date)});
      setRows(fin);
      setTotalCount(fin.length);

      setRefreshSuccess(true);
    });
  };

  useEffect(() => {
    getData();
    setRefreshHandler(() => () => getData());
    return () => setRefreshHandler(null);
  }, []);

  return (
    <Grid2 container spacing={2}>
      <Snackbar open={refreshSuccess} autoHideDuration={1000} onClose={() => setRefreshSuccess(false)}>
        <Alert onClose={() => setRefreshSuccess(false)}
          severity="success"
          variant="filled"
          sx={{width: '100%'}}>
          Refresh complete.
        </Alert>
      </Snackbar>
      <Snackbar open={refreshError} autoHideDuration={1000} onClose={() => setRefreshError(false)}>
        <Alert onClose={() => setRefreshError(false)}
          severity="error"
          variant="filled"
          sx={{width: '100%'}}>
          Refresh failed.
        </Alert>
      </Snackbar>

      <Grid2 xs={8} md={2}><Typography variant="h3" align="right">{totalCount}</Typography></Grid2>
      <Grid2 xs={4} md={1}>IN LAST {days} DAYS</Grid2>
      <Grid2 xs={0} md={6} />
      <Grid2 xs={12} md={3}>
        <Box>
          <Typography>Days to show: {days}</Typography>
          <Slider step={null} marks={[{value: 1, label: '1'}, {value: 3, label: '3'}, {value: 7, label: '7'}, {value: 15, label: '15'}, {value: 30, label: '30'},]}
            min={1} max={30} value={days}
            onChange={(e, v) => setDays(typeof v === 'number' ? v : 1)}
            onChangeCommitted={(e, v) => getData(typeof v === 'number' ? v : 1)} />
        </Box>
      </Grid2>

      <Grid2 xs={12} />
      <Grid2 xs={12} />
      <Grid2 xs={12} />

      <Grid2 xs={12}>
        <Box className="section datagrid-container">
          <DataGrid getRowHeight={() => 'auto'} pageSizeOptions={[30, 50, 100]} rows={rows}
            columns={columns} initialState={{pagination: {paginationModel: {pageSize: 100}}, sorting: {sortModel: [{field: 'date', sort: 'desc'}]}}} />
        </Box>
      </Grid2>
    </Grid2>
  );
};

export default PageLogsMails;
