import {useContext, useEffect, useRef, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {Box, Snackbar, Alert, CircularProgress, TextField, Button, LinearProgress} from '@mui/material';
import {DataGrid, GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import {Refresh} from '@mui/icons-material';
import Grid2 from '@mui/material/Unstable_Grid2';
import {ZULL_API, ZULL_DATETIME, ZULL_ITEM, ZULL_STRING} from 'zull-common-js';
import {GlobalContext} from '../helpers/globalContext';
import CommandModal, {CommandButton, Commands} from '../components/CommandModal';
import Tabbed from '../components/Tabbed';
import {RefreshContext} from '../helpers/refreshContext';

type TMemberEntry = {
  guid: number,
  accountid: number,
  account: string,
  name: string,
  race: string,
  class: string,
  level: number,
  zone: string,
  online: boolean,
  rank: number,
  rankname: string,
  pnote: string,
  offnote: string
}
type TGuildData = {
  guildid: number,
  name: string,
  leaderguid: number,
  leadername: string,
  /** UNIX timestamp */
  createdate: Date | number,
  bankmoney: number,
  membercount: number,
  membersonline: number,
  members: TMemberEntry[]
}
type TGuildBankData = {
  tabs: {
    id: number,
    name: string,
    icon: string
  }[],
  bank: {
    slot: number,
    count: number,
    item: ZULL_ITEM.ItemData.TItemData | null
  }[][]
}

const BankTab = (props: {data: TGuildBankData, tab: number}) => {
  const box = useRef(null);
  return (
    <Box ref={box} className="section" sx={{border: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', gap: '4px', height: (42 * 8) + (4 * 6)}}>
      {(!props.data.bank[props.tab] || props.data.bank[props.tab].length === 0) && <div style={{margin: '1em'}}>Tab empty.</div>}
      {props.data.bank[props.tab] && props.data.bank[props.tab].map(y => {
        if (y.item === null) return (<div key={`${props.tab}-${y.slot}`} style={{width: 42, height: 42}} />);
        return (
          <ZULL_ITEM.ItemIcon key={`${props.tab}-${y.slot}`} small parent={box}
            item={y.item} stackCount={y.count} topBound={y.slot % 7 > 3} />
        );
      })}
    </Box>
  );
};

const columnsMembers: GridColDef[] = [
  {
    field: 'online', headerName: 'ON', type: 'boolean', width: 50,
    renderCell: (p: GridRenderCellParams<TMemberEntry, boolean>) =>
      (<div style={{aspectRatio: '1/1', height: '0.75em', borderRadius: '50%', background: p.value ? 'green' : 'darkred'}} />)
  },
  {field: 'guid', headerName: 'GUID', type: 'number', width: 80},
  {
    field: 'name', headerName: 'Name', type: 'string', width: 120,
    renderCell: (p: GridRenderCellParams<TMemberEntry, string>) => (
      <Link to={`/Users/Character/${p.row.guid}`}>{p.value}</Link>
    )
  },
  {
    field: 'rank', headerName: 'Rank', type: 'number', width: 160,
    renderCell: (p: GridRenderCellParams<TMemberEntry, number>) => `${p.row.rankname} (${p.value})`
  },
  {
    field: 'account', headerName: 'Account', type: 'string', width: 120,
    renderCell: (p: GridRenderCellParams<TMemberEntry, string>) => (
      <Link to={`/Users/Account/${p.row.accountid}`}>{p.value}</Link>
    )
  },
  {field: 'pnote', headerName: 'Note', type: 'string', width: 240},
  {field: 'offnote', headerName: 'Officer note', type: 'string', width: 240},
  {field: 'race', headerName: 'Race', type: 'string', width: 100},
  {field: 'class', headerName: 'Class', type: 'string', width: 120},
  {field: 'level', headerName: 'Lvl', type: 'number', width: 60},
  {field: 'zone', headerName: 'Zone', type: 'string', width: 240}
];

const PageUsersGuild = () => {
  const {id} = useParams();
  const {globalState} = useContext(GlobalContext);
  const {setRefreshHandler} = useContext(RefreshContext);
  const commandModal = useRef<CommandModal | null>(null);

  const [refreshSuccess, setRefreshSuccess] = useState(false);
  const [refreshError, setRefreshError] = useState(false);
  const [is404, setIs404] = useState(false);
  const [data, setData] = useState<TGuildData | null>(null);

  const [inventory, setInventory] = useState<TGuildBankData | null>(null);
  const [inventoryLoading, setInventoryLoading] = useState(false);

  const getData = () => {
    if (!id) return;
    ZULL_API.POST({
      endpoint: 'admin/user/guilddata',
      authUser: globalState.username,
      authPass: globalState.password,
      body: JSON.stringify({id: parseInt(id)})
    }).then(res => {
      if (!res || !res.ok || !res.body) {
        console.error(res);
        setRefreshError(true);
        setIs404(res.status === 404);
        return;
      }
      const ret: TGuildData = JSON.parse(res.body) as TGuildData;
      ret.createdate = new Date(ret.createdate as number * 1000);
      setData(ret);
      setRefreshSuccess(true);
      setIs404(false);
    });
  };

  const getInventory = () => {
    if (!id) return;
    setInventoryLoading(true);
    ZULL_API.POST({
      endpoint: 'admin/user/guildbank',
      authUser: globalState.username,
      authPass: globalState.password,
      body: JSON.stringify({id: parseInt(id)})
    }).then(res => {
      if (!res || !res.ok || !res.body) {
        console.error(res);
        setRefreshError(true);
        setInventoryLoading(false);
        return;
      }
      const ret = JSON.parse(res.body) as TGuildBankData;
      for (let t = 0; t < ret.bank.length; t++) {
        if (ret.bank[t].length > 0) {
          for (let i = 0; i < 98; i++) { // 98 slots per bank tab (-1: zero-indexed)
            if (ret.bank[t].findIndex(x => x.slot === i) === -1)
              ret.bank[t].push({slot: i, count: 0, item: null});
          }
        }
        ret.bank[t].sort((a, b) => a.slot < b.slot ? -1 : 1);
      }
      setInventory(ret);
      setRefreshSuccess(true);
      setInventoryLoading(false);
    });
  };

  const handleCommand = (cmd: Commands, args: string, lock?: boolean) => {
    if (!commandModal.current) return;
    commandModal.current.setCommand(cmd);
    commandModal.current.setArgs(args);
    if (lock) commandModal.current.setLocked();
    commandModal.current.open();
  };

  useEffect(() => {
    getData();
    setRefreshHandler(() => getData);
    return () => setRefreshHandler(null);
  }, []);

  if (is404) return (
    <h3>Not found.</h3>
  );

  if (data === null) return (
    <CircularProgress />
  );

  return (
    <Box>
      <Snackbar open={refreshSuccess} autoHideDuration={1000} onClose={() => setRefreshSuccess(false)}>
        <Alert onClose={() => setRefreshSuccess(false)}
          severity="success"
          variant="filled"
          sx={{width: '100%'}}>
          Refresh complete.
        </Alert>
      </Snackbar>
      <Snackbar open={refreshError} autoHideDuration={1000} onClose={() => setRefreshError(false)}>
        <Alert onClose={() => setRefreshError(false)}
          severity="error"
          variant="filled"
          sx={{width: '100%'}}>
          Refresh failed.
        </Alert>
      </Snackbar>

      <h2 className="section">
        <div style={{display: 'inline-block', aspectRatio: '1/1', height: '0.75em', borderRadius: '50%', marginRight: '0.5em', background: data.membersonline > 0 ? 'green' : 'darkred'}} />
        Guild {data.name}
      </h2>
      <Box className="section">
        <Grid2 spacing={2} container>
          <Grid2 xs={12} md={4} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Link to={`/Users/Character/${data.leaderguid}`}>Leader: {data.leadername} ({data.leaderguid})</Link>
          </Grid2>
          <Grid2 xs={12} md={4}><TextField size="small" fullWidth disabled
            label="Members" value={data.membercount} /></Grid2>
          <Grid2 xs={12} md={4}><TextField size="small" fullWidth disabled
            label="Members online" value={data.membersonline} /></Grid2>
          <Grid2 xs={12} md={4}><TextField size="small" fullWidth disabled
            label="Created" value={ZULL_DATETIME.dateFormat({date: data.createdate as Date, langCode: 'en'})} /></Grid2>
          <Grid2 xs={12} md={4}><TextField size="small" fullWidth disabled
            label="Bank money" value={ZULL_STRING.goldFormat(data.bankmoney)} /></Grid2>
        </Grid2>
      </Box>


      <Tabbed tabs={[
        {
          n: 'Members', e: (
            <Box className="section datagrid-container">
              <DataGrid getRowHeight={() => 'auto'} pageSizeOptions={[30, 50, 100]} rows={data.members}
                initialState={{pagination: {paginationModel: {pageSize: 100}}, sorting: {sortModel: [{field: 'rank', sort: 'asc'}]}}}
                columns={columnsMembers} getRowId={row => row.guid} />
            </Box>
          )
        },
        {
          n: 'Actions', e: (
            <Box className="section">
              <Grid2 container spacing={2}>
                <Grid2 xs={12} md={3}><CommandButton cmd={Commands.GUILD_INVITE}
                  onClick={() => handleCommand(Commands.GUILD_INVITE, `Character "${data.name}"`)} /></Grid2>
                <Grid2 xs={12} md={3}><CommandButton cmd={Commands.GUILD_UNINVITE}
                  onClick={() => handleCommand(Commands.GUILD_UNINVITE, 'Character')} /></Grid2>
                <Grid2 xs={12} md={3}><CommandButton cmd={Commands.GUILD_RANK}
                  onClick={() => handleCommand(Commands.GUILD_RANK, 'Character 2')} /></Grid2>
                <Grid2 xs={12} md={3}><CommandButton cmd={Commands.GUILD_RENAME}
                  onClick={() => handleCommand(Commands.GUILD_RENAME, `"${data.name}" "NewName"`)} /></Grid2>
                <Grid2 xs={12} md={3}><CommandButton cmd={Commands.GUILD_DELETE}
                  onClick={() => handleCommand(Commands.GUILD_DELETE, `${data.name}`, true)} /></Grid2>
              </Grid2>
            </Box>
          )
        },

        /// ///////////////////////////////////////////////
        /// ///////////////////////////////////////////////
        /// ///////////////////////////////////////////////

        {
          n: 'Bank', e: (
            <Box className="section" sx={{width: '100%', overflowX: 'scroll'}}>
              <Grid2 container spacing={2}>
                <Grid2 xs={12} md={4}>
                  <Button variant="contained" className="gridbutton" disabled={inventoryLoading}
                    startIcon={<Refresh />} onClick={getInventory}>
                    Load Bank
                  </Button>
                </Grid2>
                <Grid2 xs={12} md={8}><LinearProgress style={{display: inventoryLoading ? 'block' : 'none'}} /></Grid2>
                <Grid2 xs={12} />

                {inventory && <Tabbed tabs={inventory.tabs.map(x => (
                  {
                    n: x.name ? `${x.id}: ${x.name}` : (x.id + 1).toString(), i: x.icon ? (<img key={x.id} src={ZULL_ITEM.getIconURL(x.icon).toLowerCase()} alt=""
                      style={{width: '3em'}} />) : undefined,
                    e: (
                      <BankTab key={x.id} data={inventory} tab={x.id} />
                    )
                  }
                ))} />}
              </Grid2>
            </Box>
          )
        }
      ]} />

      <CommandModal onDone={() => getData()} ref={commandModal} globalState={globalState} />
    </Box>
  );
};

export default PageUsersGuild;
