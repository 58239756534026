import {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Alert, Box, Button, CircularProgress, Collapse, FormControl, InputLabel, MenuItem, Select, Snackbar, Stack, TextField} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {Add} from '@mui/icons-material';
import {DataGrid, GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import {ZULL_API, ZULL_DATETIME} from 'zull-common-js';
import {GlobalContext} from '../helpers/globalContext';
import {RefreshContext} from '../helpers/refreshContext';

type TRenameRow = {
  id: number,
  date: Date | String,
  accountid: number,
  account: string,
  characterid: number | 0,
  old_name: string,
  current_name: string | null,
  renamed_by: string,
  communication: string | null,
  notes: string | null
}
enum CommunicationOption {
  NONE,
  INGAME,
  INGAME_MAIL,
  DISCORD
}
const CommunicationOptionStrings: Record<CommunicationOption, string> = {
  [CommunicationOption.NONE]: 'None',
  [CommunicationOption.INGAME]: 'Ingame',
  [CommunicationOption.INGAME_MAIL]: 'Ingame-Mail',
  [CommunicationOption.DISCORD]: 'Discord'
};



const columnsRenames: GridColDef[] = [
  {field: 'date', headerName: 'Date', type: 'dateTime', width: 180, editable: true},
  {
    field: 'account', headerName: 'Account', type: 'string', width: 120,
    renderCell: (p: GridRenderCellParams<TRenameRow, string>) => (
      <Link to={`/Users/Account/${p.row.accountid}`}>{p.value}</Link>
    )
  },
  {
    field: 'current_name', headerName: 'Character', type: 'string', width: 120,
    renderCell: (p: GridRenderCellParams<TRenameRow, string>) => p.row.characterid !== 0 ?
      (<Link to={`/Users/Character/${p.row.characterid}`}>{p.value || p.row.characterid}</Link>) :
      (<em style={{fontSize: '0.7em'}}>Unknown</em>)
  },
  {field: 'old_name', headerName: 'Old Name', type: 'string', width: 120},
  {field: 'renamed_by', headerName: 'Renamed By', type: 'string', width: 120},
  {
    field: 'communication', headerName: 'Communication', type: 'singleSelect', width: 120,
    editable: true, valueOptions: Object.values(CommunicationOptionStrings)
  },
  {field: 'notes', headerName: 'Notes', type: 'string', width: 240, editable: true}
];

const PageUsersRenames = () => {
  const {globalState} = useContext(GlobalContext);
  const {setRefreshHandler} = useContext(RefreshContext);

  const [refreshSuccess, setRefreshSuccess] = useState(false);
  const [refreshError, setRefreshError] = useState(false);
  const [data, setData] = useState<TRenameRow[] | null>(null);

  const [newDataError, setNewDataError] = useState(false);
  const [newAccountID, setNewAccountID] = useState(0);
  const [newAccountIDError, setNewAccountIDError] = useState(false);
  const [newCharacterID, setNewCharacterID] = useState(0);
  const [newOldName, setNewOldName] = useState('');
  const [newCommunication, setNewCommunication] = useState(CommunicationOptionStrings[CommunicationOption.NONE]);
  const [newNotes, setNewNotes] = useState('');


  const getData = () => {
    ZULL_API.GET({
      endpoint: 'admin/user/renames',
      authUser: globalState.username,
      authPass: globalState.password
    }).then(res => {
      if (!res || !res.ok || !res.body) {
        console.error(res);
        setRefreshError(true);
        return;
      }
      const ret: TRenameRow[] = JSON.parse(res.body) as TRenameRow[];
      for (let i = 0; i < ret.length; i++) ret[i].date = new Date(ret[i].date as string);
      setData(ret);
      setRefreshSuccess(true);
    });
  };

  useEffect(() => {
    getData();
    setRefreshHandler(() => getData);
    return () => setRefreshHandler(null);
  }, []);

  if (data === null) return (
    <CircularProgress />
  );

  return (
    <Box>
      <Snackbar open={refreshSuccess} autoHideDuration={1000} onClose={() => setRefreshSuccess(false)}>
        <Alert onClose={() => setRefreshSuccess(false)}
          severity="success"
          variant="filled"
          sx={{width: '100%'}}>
          Refresh complete.
        </Alert>
      </Snackbar>
      <Snackbar open={refreshError} autoHideDuration={1000} onClose={() => setRefreshError(false)}>
        <Alert onClose={() => setRefreshError(false)}
          severity="error"
          variant="filled"
          sx={{width: '100%'}}>
          Refresh failed.
        </Alert>
      </Snackbar>

      <Stack direction="column">
        <Box className="section datagrid-container">
          <DataGrid getRowHeight={() => 'auto'} pageSizeOptions={[30, 50, 100]} rows={data}
            columns={columnsRenames} initialState={{
              pagination: {paginationModel: {pageSize: 100}},
              sorting: {sortModel: [{field: 'date', sort: 'desc'}]}
            }}
            processRowUpdate={(updatedRow: TRenameRow, originalRow: TRenameRow) => {
              if (JSON.stringify(updatedRow) !== JSON.stringify(originalRow)) ZULL_API.POST({
                endpoint: 'admin/user/editrename',
                authUser: globalState.username,
                authPass: globalState.password,
                body: JSON.stringify({
                  id: updatedRow.id,
                  date: ZULL_DATETIME.timestampFormatIso(updatedRow.date as Date, ZULL_DATETIME.TimestampFormat.yyyymmddhhmmss),
                  communication: updatedRow.communication === CommunicationOptionStrings[CommunicationOption.NONE] ? null : updatedRow.communication,
                  notes: updatedRow.notes || null
                })
              }).then(res => {
                if (res.ok) {
                  getData();
                } else setRefreshError(true);
              });
              return updatedRow;
            }} />
        </Box>



        <Box className="section">
          <h4>Add manually</h4>
          <br />
          <Stack direction="column">
            <Grid2 container spacing={2}>
              <Grid2 xs={12} md={3}><TextField size="small" fullWidth required
                label="Account ID" value={newAccountID} type="number"
                onChange={e => setNewAccountID(parseInt(e.target.value))} /></Grid2>
              <Grid2 xs={12} md={3}><TextField size="small" fullWidth required
                label="Character ID (0 for unknown)" value={newCharacterID} type="number"
                onChange={e => setNewCharacterID(parseInt(e.target.value))} /></Grid2>
              <Grid2 xs={12} md={3}><TextField size="small" fullWidth required
                label="Old (current) name" value={newOldName}
                onChange={e => setNewOldName(e.target.value)} /></Grid2>
              <Grid2 xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel id="newRenameCommunicationLabel">Communication</InputLabel>
                  <Select value={newCommunication} fullWidth size="small"
                    label="Communication" labelId="newRenameCommunicationLabel" required
                    onChange={e => setNewCommunication(e.target.value)}>
                    <MenuItem value={CommunicationOptionStrings[CommunicationOption.NONE]}><em>{CommunicationOptionStrings[CommunicationOption.NONE]}</em></MenuItem>
                    <MenuItem value={CommunicationOptionStrings[CommunicationOption.INGAME]}>{CommunicationOptionStrings[CommunicationOption.INGAME]}</MenuItem>
                    <MenuItem value={CommunicationOptionStrings[CommunicationOption.INGAME_MAIL]}>{CommunicationOptionStrings[CommunicationOption.INGAME_MAIL]}</MenuItem>
                    <MenuItem value={CommunicationOptionStrings[CommunicationOption.DISCORD]}>{CommunicationOptionStrings[CommunicationOption.DISCORD]}</MenuItem>
                  </Select>
                </FormControl>
              </Grid2>
              <Grid2 xs={12}><TextField size="small" fullWidth
                label="Notes" value={newNotes}
                onChange={e => setNewNotes(e.target.value)} /></Grid2>
              <Grid2 xs={8} md={11} />
              <Grid2 xs={4} md={1}>
                <Button variant="contained" className="gridbutton" onClick={() => {
                  if (newCharacterID === undefined || newCharacterID === null || Number.isNaN(newCharacterID) || !newOldName) {
                    setNewDataError(true);
                    return;
                  }
                  if (newAccountID === 0) {
                    setNewAccountIDError(true);
                    return;
                  }
                  setNewDataError(false);
                  setNewAccountIDError(false);
                  ZULL_API.POST({
                    endpoint: 'admin/user/renames',
                    authUser: globalState.username,
                    authPass: globalState.password,
                    body: JSON.stringify({
                      accountid: newAccountID,
                      characterid: newCharacterID,
                      old_name: newOldName,
                      renamed_by: globalState.username,
                      communication: newCommunication === CommunicationOptionStrings[CommunicationOption.NONE] ? null : newCommunication,
                      notes: newNotes || null
                    })
                  }).then(res => {
                    if (res.ok) {
                      getData();
                    } else setRefreshError(true);
                  });
                }}>
                  <Add />
                </Button>
              </Grid2>
            </Grid2>
            <Collapse in={newAccountIDError} sx={{marginBottom: '1em'}}>
              <Alert onClose={() => setNewAccountIDError(false)}
                severity="error"
                variant="filled"
                sx={{width: '100%'}}>
                Account ID 0 can not be correct.
              </Alert>
            </Collapse>
            <Collapse in={newDataError} sx={{marginBottom: '1em'}}>
              <Alert onClose={() => setNewDataError(false)}
                severity="error"
                variant="filled"
                sx={{width: '100%'}}>
                Please fill out the required fields.
              </Alert>
            </Collapse>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default PageUsersRenames;
