import {useContext, useEffect, useRef, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {Box, Snackbar, Alert, CircularProgress, TextField, Button, LinearProgress, Card, Stack, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {Refresh} from '@mui/icons-material';
import Grid2 from '@mui/material/Unstable_Grid2';
import {ZULL_API, ZULL_DATETIME, ZULL_ITEM, ZULL_STRING} from 'zull-common-js';
import {GlobalContext} from '../helpers/globalContext';
import CommandModal, {CommandButton, Commands} from '../components/CommandModal';
import Tabbed from '../components/Tabbed';
import {RefreshContext} from '../helpers/refreshContext';

type TCharacterData = {
  guid: number,
  account: number,
  username: string,
  name: string,
  race: string,
  class: string,
  gender: string,
  level: number,
  money: number,
  online: boolean,
  totaltime: number,
  zone: string,
  creation_date: string,
  guildid: number | null,
  guildname: string | null,

  mutetime: Date | number,
  mutereason: string,
  muteby: string,
  mutehistory: {
    mutedate: Date | number,
    mutetime: number,
    mutedby: string,
    mutereason: string
  }[] | null,
  baninfo: {
    bandate: Date | number,
    unbandate: Date | number,
    bannedby: string,
    banreason: string
  } | null,
  banhistory: {
    bandate: Date | number,
    unbandate: Date | number,
    bannedby: string,
    banreason: string
  }[] | null
}

const PageUsersCharacter = () => {
  const {id} = useParams();
  const {globalState} = useContext(GlobalContext);
  const {setRefreshHandler} = useContext(RefreshContext);
  const commandModal = useRef<CommandModal | null>(null);

  const [refreshSuccess, setRefreshSuccess] = useState(false);
  const [refreshError, setRefreshError] = useState(false);
  const [is404, setIs404] = useState(false);
  const [data, setData] = useState<TCharacterData | null>(null);

  const [inventory, setInventory] = useState<ZULL_ITEM.ItemData.IInventoryData | null>(null);
  const [inventoryLoading, setInventoryLoading] = useState(false);

  const getData = () => {
    if (!id) return;
    ZULL_API.POST({
      endpoint: 'admin/user/characterdata',
      authUser: globalState.username,
      authPass: globalState.password,
      body: JSON.stringify({id: parseInt(id)})
    }).then(res => {
      if (!res || !res.ok || !res.body) {
        console.error(res);
        setRefreshError(true);
        setIs404(res.status === 404);
        return;
      }
      const ret: TCharacterData = JSON.parse(res.body) as TCharacterData;
      ret.mutetime = new Date(ret.mutetime as number * 1000);
      if (ret.mutehistory) {
        for (let i = 0; i < ret.mutehistory.length; i++) {
          ret.mutehistory[i].mutedate = new Date(ret.mutehistory[i].mutedate as number * 1000);
        }
      }
      if (ret.baninfo) {
        ret.baninfo.bandate = new Date(ret.baninfo.bandate as number * 1000);
        ret.baninfo.unbandate = new Date(ret.baninfo.unbandate as number * 1000);
      }
      if (ret.banhistory) {
        for (let i = 0; i < ret.banhistory.length; i++) {
          ret.banhistory[i].bandate = new Date(ret.banhistory[i].bandate as number * 1000);
          ret.banhistory[i].unbandate = new Date(ret.banhistory[i].unbandate as number * 1000);
        }
      }
      setData(ret);
      setRefreshSuccess(true);
      setIs404(false);
    });
  };

  const getInventory = () => {
    if (!id) return;
    setInventoryLoading(true);
    ZULL_API.POST({
      endpoint: 'admin/user/inventory',
      authUser: globalState.username,
      authPass: globalState.password,
      body: JSON.stringify({id: parseInt(id)})
    }).then(res => {
      if (!res || !res.ok || !res.body) {
        console.error(res);
        setRefreshError(true);
        setInventoryLoading(false);
        return;
      }
      setInventory(JSON.parse(res.body) as ZULL_ITEM.ItemData.IInventoryData);
      setRefreshSuccess(true);
      setInventoryLoading(false);
    });
  };

  const handleCommand = (cmd: Commands, args: string, lock?: boolean) => {
    if (!commandModal.current) return;
    commandModal.current.setCommand(cmd);
    commandModal.current.setArgs(args);
    if (lock) commandModal.current.setLocked();
    commandModal.current.open();
  };

  useEffect(() => {
    getData();
    setRefreshHandler(() => getData);
    return () => setRefreshHandler(null);
  }, []);

  if (is404) return (
    <h3>Not found.</h3>
  );

  if (data === null) return (
    <CircularProgress />
  );

  return (
    <Box>
      <Snackbar open={refreshSuccess} autoHideDuration={1000} onClose={() => setRefreshSuccess(false)}>
        <Alert onClose={() => setRefreshSuccess(false)}
          severity="success"
          variant="filled"
          sx={{width: '100%'}}>
          Refresh complete.
        </Alert>
      </Snackbar>
      <Snackbar open={refreshError} autoHideDuration={1000} onClose={() => setRefreshError(false)}>
        <Alert onClose={() => setRefreshError(false)}
          severity="error"
          variant="filled"
          sx={{width: '100%'}}>
          Refresh failed.
        </Alert>
      </Snackbar>

      <h2 className="section">
        <div style={{display: 'inline-block', aspectRatio: '1/1', height: '0.75em', borderRadius: '50%', marginRight: '0.5em', background: data.online ? 'green' : 'darkred'}} />
        Character {data.name}
      </h2>
      <Box className="section">
        <Grid2 spacing={2} container>
          <Grid2 xs={12} md={4} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Link to={`/Users/Account/${data.account}`}>{data.username} ({data.account})</Link>
          </Grid2>
          <Grid2 xs={12} md={4}><TextField size="small" fullWidth disabled
            label="Level" value={data.level} /></Grid2>
          <Grid2 xs={12} md={4}><TextField size="small" fullWidth disabled
            label="Class" value={data.class} /></Grid2>

          <Grid2 xs={12} md={4}><TextField size="small" fullWidth disabled
            label="Race" value={data.race} /></Grid2>
          <Grid2 xs={12} md={4}><TextField size="small" fullWidth disabled
            label="Gender" value={data.gender} /></Grid2>
          <Grid2 xs={12} md={4}><TextField size="small" fullWidth disabled
            label="Zone" value={data.zone} /></Grid2>

          <Grid2 xs={12} md={4}><TextField size="small" fullWidth disabled
            label="Money" value={ZULL_STRING.goldFormat(data.money)} /></Grid2>
          <Grid2 xs={12} md={4}><TextField size="small" fullWidth disabled
            label="Created" value={data.creation_date} /></Grid2>
          <Grid2 xs={12} md={4}><TextField size="small" fullWidth disabled
            label="Total Time" value={ZULL_DATETIME.timeFormat(data.totaltime, ZULL_DATETIME.TimeFormat.HHmmssD)} /></Grid2>

          <Grid2 xs={12} md={4} sx={{display: data.guildid ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center'}}>
            <Link to={`/Users/Guild/${data.guildid}`}>Guild: {data.guildname} ({data.guildid})</Link>
          </Grid2>
        </Grid2>
      </Box>


      <Box className="section yellow" hidden={data.mutehistory === null}>
        <Grid2 container spacing={2} sx={{display: (data.mutetime as Date) < (new Date()) ? 'none' : undefined}}>
          <Grid2 xs={12}><h4>Active on Account:</h4></Grid2>
          <Grid2 xs={12} md={4}><TextField size="small" fullWidth disabled
            label="Muted until" value={(data.mutetime as Date).toISOString().replace('T', ' ').replace('.000Z', '')} /></Grid2>
          <Grid2 xs={12} md={4}><TextField size="small" fullWidth disabled
            label="Muted by" value={data.muteby} /></Grid2>
          <Grid2 xs={12} md={4}><TextField size="small" fullWidth disabled
            label="Reason" value={data.mutereason} /></Grid2>
          <Grid2 xs={12} />
          <Grid2 xs={12} />
        </Grid2>
        <h4>History on Account:</h4>
        <TableContainer component={Paper}>
          <Table sx={{minWidth: 560}} size="small">
            <TableHead>
              <TableRow>
                <TableCell>Mutedate</TableCell>
                <TableCell align="right">Mute Duration</TableCell>
                <TableCell align="right">Muted by</TableCell>
                <TableCell align="right">Reason</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.mutehistory?.map((x, i) => (
                <TableRow key={i}>
                  <TableCell>{(x.mutedate as Date).toISOString().replace('T', ' ').replace('.000Z', '')}</TableCell>
                  <TableCell align="right">{ZULL_DATETIME.timeFormat(x.mutetime * 60, ZULL_DATETIME.TimeFormat.HHmmssD)}</TableCell>
                  <TableCell align="right">{x.mutedby}</TableCell>
                  <TableCell align="right">{x.mutereason}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>


      <Box className="section red" hidden={data.baninfo === null && data.banhistory === null}>
        <Grid2 container spacing={2} sx={data.baninfo === null ? {display: 'none'} : undefined}>
          <Grid2 xs={12}><h4>Active on Account:</h4></Grid2>
          <Grid2 xs={12} md={6}><TextField size="small" fullWidth disabled
            label="Bandate" value={data.baninfo ? (data.baninfo?.bandate as Date).toISOString().replace('T', ' ').replace('.000Z', '') : ''} /></Grid2>
          <Grid2 xs={12} md={6}><TextField size="small" fullWidth disabled
            label="Unbandate"
            value={data.baninfo ? data.baninfo?.bandate < data.baninfo?.unbandate ? (data.baninfo?.unbandate as Date).toISOString().replace('T', ' ').replace('.000Z', '') : 'PERMANENT' : ''} /></Grid2>
          <Grid2 xs={12} md={6}><TextField size="small" fullWidth disabled
            label="Banned by" value={data.baninfo?.bannedby} /></Grid2>
          <Grid2 xs={12} md={6}><TextField size="small" fullWidth disabled
            label="Reason" value={data.baninfo?.banreason} /></Grid2>
        </Grid2>
        {data.baninfo !== null && <br />}
        <h4>History on Account:</h4>
        <TableContainer component={Paper}>
          <Table sx={{minWidth: 560}} size="small">
            <TableHead>
              <TableRow>
                <TableCell>Bandate</TableCell>
                <TableCell align="right">Reason</TableCell>
                <TableCell align="right">Banned by</TableCell>
                <TableCell align="right">Unbandate</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.banhistory?.map((x, i) => (
                <TableRow key={i}>
                  <TableCell>{(x.bandate as Date).toISOString().replace('T', ' ').replace('.000Z', '')}</TableCell>
                  <TableCell align="right">{x.banreason}</TableCell>
                  <TableCell align="right">{x.bannedby}</TableCell>
                  <TableCell align="right">{x.bandate < x.unbandate ? (x.unbandate as Date).toISOString().replace('T', ' ').replace('.000Z', '') : 'PERMANENT'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>


      <Tabbed tabs={[
        {
          n: 'Actions', e: (
            <Box className="section">
              <Grid2 container spacing={2}>
                <Grid2 xs={12} md={3}><CommandButton cmd={Commands.CHARACTER_CHANGEACCOUNT}
                  onClick={() => handleCommand(Commands.CHARACTER_CHANGEACCOUNT, `NewAccount ${data.name}`)} /></Grid2>
                <Grid2 xs={12} md={3}><CommandButton cmd={Commands.CHARACTER_CHANGEFACTION}
                  onClick={() => handleCommand(Commands.CHARACTER_CHANGEFACTION, data.name, true)} /></Grid2>
                <Grid2 xs={12} md={3}><CommandButton cmd={Commands.CHARACTER_CHANGERACE}
                  onClick={() => handleCommand(Commands.CHARACTER_CHANGERACE, data.name, true)} /></Grid2>
                <Grid2 xs={12} md={3}><CommandButton cmd={Commands.CHARACTER_RENAME}
                  onClick={() => handleCommand(Commands.CHARACTER_RENAME, `${data.name} 1`, true)} /></Grid2>

                <Grid2 xs={12} md={3}><CommandButton cmd={Commands.CHARACTER_ITEMRESTORE}
                  onClick={() => handleCommand(Commands.CHARACTER_ITEMRESTORE, `000 ${data.name}`)} /></Grid2>
                <Grid2 xs={12} md={3}><CommandButton cmd={Commands.CHARACTER_ITEMRESTORE_LIST}
                  onClick={() => handleCommand(Commands.CHARACTER_ITEMRESTORE_LIST, `${data.name} 0`)} /></Grid2>
                <Grid2 xs={12} md={3}><CommandButton cmd={Commands.CHARACTER_UNSTUCK}
                  onClick={() => handleCommand(Commands.CHARACTER_UNSTUCK, data.name, true)} /></Grid2>
                <Grid2 xs={12} md={3}><CommandButton cmd={Commands.CHARACTER_KICK}
                  onClick={() => handleCommand(Commands.CHARACTER_KICK, data.name)} /></Grid2>

                <Grid2 xs={12} md={3}><CommandButton cmd={Commands.CHARACTER_SENDMESSAGE}
                  onClick={() => handleCommand(Commands.CHARACTER_SENDMESSAGE, `${data.name} "Message"`)} /></Grid2>
                <Grid2 xs={12} md={3}><CommandButton cmd={Commands.CHARACTER_SENDMAIL}
                  onClick={() => handleCommand(Commands.CHARACTER_SENDMAIL, `${data.name} "Subject" "Message"`)} /></Grid2>
                <Grid2 xs={12} md={3}><CommandButton cmd={Commands.CHARACTER_SENDMONEY}
                  onClick={() => handleCommand(Commands.CHARACTER_SENDMONEY, `${data.name} "Subject" "Message" 10000`)} /></Grid2>
                <Grid2 xs={12} md={3}><CommandButton cmd={Commands.CHARACTER_SENDITEMS}
                  onClick={() => handleCommand(Commands.CHARACTER_SENDITEMS, `${data.name} "Subject" "Message" 17202:1`)} /></Grid2>

                <Grid2 xs={12} md={3}><CommandButton cmd={Commands.CHARACTER_FREEZE}
                  onClick={() => handleCommand(Commands.CHARACTER_FREEZE, data.name, true)} /></Grid2>
                <Grid2 xs={12} md={3}><CommandButton cmd={Commands.CHARACTER_UNFREEZE}
                  onClick={() => handleCommand(Commands.CHARACTER_UNFREEZE, data.name, true)} /></Grid2>
                <Grid2 xs={12} md={3}><CommandButton cmd={Commands.CHARACTER_MUTE}
                  onClick={() => handleCommand(Commands.CHARACTER_MUTE, `${data.name} 60 Spam`)} /></Grid2>
                <Grid2 xs={12} md={3}><CommandButton cmd={Commands.CHARACTER_UNMUTE}
                  onClick={() => handleCommand(Commands.CHARACTER_UNMUTE, data.name, true)} /></Grid2>
              </Grid2>
            </Box>
          )
        },

        /// ///////////////////////////////////////////////
        /// ///////////////////////////////////////////////
        /// ///////////////////////////////////////////////

        {
          n: 'Inventory', e: (
            <Box className="section" sx={{width: '100%', overflowX: 'scroll'}}>
              <Grid2 container spacing={2}>
                <Grid2 xs={12} md={4}>
                  <Button variant="contained" className="gridbutton" disabled={inventoryLoading}
                    startIcon={<Refresh />} onClick={getInventory}>
                    Load Inventory
                  </Button>
                </Grid2>
                <Grid2 xs={12} md={8}><LinearProgress style={{display: inventoryLoading ? 'block' : 'none'}} /></Grid2>
                <Grid2 xs={12} />

                {inventory && <Tabbed tabs={[
                  {
                    n: 'Equipped', e: (
                      <Box className="section" sx={{border: 'none'}}>
                        <ZULL_ITEM.InventoryEquipped data={inventory.equipped}
                          name={data.name} class={data.class} level={data.level} />
                      </Box>
                    )
                  },
                  {
                    n: 'Inventory', e: inventory.inventory.length > 0 ? (
                      <Box className="section" sx={{border: 'none'}}>
                        <ZULL_ITEM.Inventory type="bags" bags={inventory.equippedBags} items={inventory.inventory} />
                      </Box>
                    ) : (<Box className="section">Inventory empty.</Box>)
                  },
                  {
                    n: 'Bank', e: inventory.bank.length > 0 ? (
                      <Box className="section" sx={{border: 'none'}}>
                        <ZULL_ITEM.Inventory type="bank" bags={inventory.bankBags} items={inventory.bank} />
                      </Box>
                    ) : (<Box className="section">Bank empty.</Box>)
                  },
                  {
                    n: 'Currency', e: inventory.currency.length > 0 ? (
                      <Box className="section">
                        <Grid2 container spacing={2}>
                          {inventory.currency.map(x => (
                            <Grid2 xs={12} md={3}>
                              <Card sx={{padding: 1}}>
                                <Stack direction="row" spacing={2} useFlexGap
                                  sx={{alignItems: 'center'}}>
                                  <ZULL_ITEM.ItemIcon item={x.item} small noTooltip />
                                  <span>{x.item.name}</span>
                                  <span style={{flexGrow: 1, textAlign: 'right'}}><pre>{x.count.toString().padStart(6, ' ')}x</pre></span>
                                </Stack>
                              </Card>
                            </Grid2>
                          ))}
                        </Grid2>
                      </Box>
                    ) : (<Box className="section">Bank empty.</Box>)
                  }
                ]} />}
              </Grid2>
            </Box>
          )
        }
      ]} />

      <CommandModal onDone={() => getData()} ref={commandModal} globalState={globalState} />
    </Box>
  );
};

export default PageUsersCharacter;
