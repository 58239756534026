import {useContext, useEffect, useRef, useState} from 'react';
import {Alert, Box, Button, LinearProgress, Snackbar, TextField, Typography} from '@mui/material';
import {DataGrid, GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {Search} from '@mui/icons-material';
import {Link, useLocation} from 'react-router-dom';
import {ZULL_API, ZULL_STRING} from 'zull-common-js';
import {GlobalContext} from '../helpers/globalContext';
import Tabbed from '../components/Tabbed';
import {RefreshContext} from '../helpers/refreshContext';

type TRowAccount = {
  id: number,
  username: string,
  email: string,
  joindate: Date | string,
  last_ip: string,
  last_login: Date | string,
  online: boolean,
  match_on: string
};
type TRowCharacter = {
  guid: number,
  account: string,
  accountid: number,
  name: string,
  race: string,
  class: string,
  level: number,
  online: boolean,
  zone: string,
  guildid: number | null,
  guildname: string | null,
  match_on: string
}
type TRowGuild = {
  guildid: number,
  name: string,
  leaderguid: number,
  leadername: string,
  createdate: Date | number,
  bankmoney: number,
  membercount: number,
  membersonline: number,
  match_on: string
}

const MatchOnCell = (props: {row: TRowAccount | TRowCharacter | TRowGuild, prop: string, v?: number | string, partial?: string}) => (
  props.row.match_on === props.prop ?
    !props.partial ? (<strong style={{color: '#aaaaff'}}>{props.v}</strong>) :
      // eslint-disable-next-line react/jsx-indent
      <span>
        {props.v?.toString().split(new RegExp(`(${props.partial})`, 'gi')).map((x, i) => {
          if (x.toLowerCase() === props.partial?.toLowerCase()) return (<strong key={`match${i}`} style={{color: '#aaaaff'}}>{x}</strong>);
          return (<span key={`match${i}`}>{x}</span>);
        })}
      </span> :
    (<span>{props.v}</span>)
);

const PageUsers = () => {
  const location = useLocation();
  const {globalState} = useContext(GlobalContext);
  const {setRefreshHandler} = useContext(RefreshContext);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshSuccess, setRefreshSuccess] = useState(false);
  const [refreshError, setRefreshError] = useState(false);
  const [lastPattern, setLastPattern] = useState('');
  const [rowsAccounts, setRowsAccounts] = useState<TRowAccount[]>([]);
  const [rowsCharacters, setRowsCharacters] = useState<TRowCharacter[]>([]);
  const [rowsGuilds, setRowsGuilds] = useState<TRowGuild[]>([]);

  const searchField = useRef<HTMLInputElement | null>(null);



  const parseData = (res: string) => {
    const ret = JSON.parse(res) as {accounts: TRowAccount[], characters: TRowCharacter[], guilds: TRowGuild[]};
    for (let i = 0; i < ret.accounts.length; i++)
      ret.accounts[i] = {
        ...ret.accounts[i],
        joindate: new Date(ret.accounts[i].joindate),
        last_login: new Date(ret.accounts[i].last_login)
      };
    for (let i = 0; i < ret.guilds.length; i++) ret.guilds[i].createdate = new Date(ret.guilds[i].createdate as number * 1000);
    setRowsAccounts(ret.accounts);
    setRowsCharacters(ret.characters);
    setRowsGuilds(ret.guilds);
  };

  const search = (searchString?: string) => {
    if (!searchString && searchField.current) searchString = searchField.current.value.trim();
    if (location.state && location.state.pattern && !searchString) searchString = location.state.pattern;
    if (searchString === undefined) searchString = '';
    setLastPattern(searchString);
    if (searchField.current) searchField.current.value = searchString;

    ZULL_API.POST({
      endpoint: 'admin/user/search',
      authUser: globalState.username,
      authPass: globalState.password,
      body: JSON.stringify({pattern: searchString || (searchString !== '' ? searchString : 'online')})
    }).then(res => {
      if (!res || !res.ok || !res.body) {
        console.error(res);
        setRefreshError(true);
        return;
      }
      parseData(res.body);
      setRefreshSuccess(true);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    search();
    setRefreshHandler(() => () => search());
    return () => setRefreshHandler(null);
  }, []);


  const columnsAccounts: GridColDef[] = [
    {
      field: 'online', headerName: 'ON', type: 'boolean', width: 50,
      renderCell: (p: GridRenderCellParams<TRowAccount, boolean>) =>
        (<div style={{aspectRatio: '1/1', height: '0.75em', borderRadius: '50%', background: p.value ? 'green' : 'darkred'}} />)
    },
    {
      field: 'id', headerName: 'ID', type: 'number', width: 60,
      renderCell: (p: GridRenderCellParams<TRowAccount, string>) => (<MatchOnCell row={p.row} v={p.value} prop="id" />)
    },
    {
      field: 'username', headerName: 'User', type: 'string', width: 120,
      renderCell: (p: GridRenderCellParams<TRowAccount, string>) => (
        <Link to={`/Users/Account/${p.row.id}`}>
          <MatchOnCell row={p.row} v={p.value} prop="username"
            partial={lastPattern} />
        </Link>
      )
    },
    {field: 'joindate', headerName: 'Joined', type: 'date', width: 100},
    {field: 'last_login', headerName: 'Last Login', type: 'dateTime', width: 180},
    {
      field: 'last_ip', headerName: 'IP', type: 'number', width: 120,
      renderCell: (p: GridRenderCellParams<TRowAccount, string>) => (
        // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/interactive-supports-focus
        <a onClick={() => search(p.value)} role="button" style={{cursor: 'pointer'}}>
          <MatchOnCell row={p.row} v={p.value} prop="last_ip" />
        </a>
      )
    },
    {
      field: 'email', headerName: 'Email', type: 'string', width: 300,
      renderCell: (p: GridRenderCellParams<TRowAccount, string>) => (<MatchOnCell row={p.row} v={p.value} prop="email"
        partial={lastPattern} />)
    }
  ];

  const columnsCharacters: GridColDef[] = [
    {
      field: 'online', headerName: 'ON', type: 'boolean', width: 50,
      renderCell: (p: GridRenderCellParams<TRowCharacter, boolean>) =>
        (<div style={{aspectRatio: '1/1', height: '0.75em', borderRadius: '50%', background: p.value ? 'green' : 'darkred'}} />)
    },
    {
      field: 'guid', headerName: 'GUID', type: 'number', width: 80,
      renderCell: (p: GridRenderCellParams<TRowCharacter, number>) => (<MatchOnCell row={p.row} v={p.value} prop="guid" />)
    },
    {
      field: 'account', headerName: 'ACC', type: 'string', width: 120,
      renderCell: (p: GridRenderCellParams<TRowCharacter, number>) => (
        <Link to={`/Users/Account/${p.row.accountid}`}>
          <MatchOnCell row={p.row} v={p.value} prop="account" />
        </Link>
      )
    },
    {
      field: 'name', headerName: 'Name', type: 'string', width: 120,
      renderCell: (p: GridRenderCellParams<TRowCharacter, string>) => (
        <Link to={`/Users/Character/${p.row.guid}`}>
          <MatchOnCell row={p.row} v={p.value} prop="name"
            partial={lastPattern} />
        </Link>
      )
    },
    {field: 'race', headerName: 'Race', type: 'string', width: 100},
    {field: 'class', headerName: 'Class', type: 'string', width: 120},
    {field: 'level', headerName: 'Lvl', type: 'number', width: 60},
    {field: 'zone', headerName: 'Zone', type: 'string', width: 240},
    {
      field: 'guildname', headerName: 'Guild', type: 'string', width: 180,
      renderCell: (p: GridRenderCellParams<TRowCharacter, string>) => (
        <Link to={`/Users/Guild/${p.row.guildid}`}>
          <MatchOnCell row={p.row} v={p.value} prop="guildname"
            partial={lastPattern} />
        </Link>
      )
    }
  ];

  const columnsGuilds: GridColDef[] = [
    {
      field: 'membersonline', headerName: 'ON', type: 'number', width: 50,
      renderCell: (p: GridRenderCellParams<TRowGuild, number>) =>
        (<div style={{aspectRatio: '1/1', height: '0.75em', borderRadius: '50%', background: p.value && p.value > 0 ? 'green' : 'darkred'}} />)
    },
    {
      field: 'guildid', headerName: 'ID', type: 'number', width: 80,
      renderCell: (p: GridRenderCellParams<TRowGuild, number>) => (<MatchOnCell row={p.row} v={p.value} prop="guildid" />)
    },
    {
      field: 'name', headerName: 'Name', type: 'string', width: 180,
      renderCell: (p: GridRenderCellParams<TRowGuild, string>) => (
        <Link to={`/Users/Guild/${p.row.guildid}`}>
          <MatchOnCell row={p.row} v={p.value} prop="guildname"
            partial={lastPattern} />
        </Link>
      )
    },
    {field: 'createdate', headerName: 'Created', type: 'date', width: 100},
    {field: 'membercount', headerName: 'Members', type: 'number', width: 80},
    {
      field: 'membersonline2', headerName: 'Online', type: 'number', width: 80,
      renderCell: (p: GridRenderCellParams<TRowGuild, string>) => (p.row.membersonline.toString())
    },
    {
      field: 'leadername', headerName: 'Leader', type: 'string', width: 120,
      renderCell: (p: GridRenderCellParams<TRowGuild, string>) => (<Link to={`/Users/Character/${p.row.leaderguid}`}>{p.value}</Link>)
    },
    {
      field: 'bankmoney', headerName: 'Money', type: 'number', width: 120,
      renderCell: (p: GridRenderCellParams<TRowGuild, number>) => (ZULL_STRING.goldFormat(p.value ?? 0))
    }
  ];



  return (
    <Grid2 container spacing={2}>
      <Snackbar open={refreshSuccess} autoHideDuration={1000} onClose={() => setRefreshSuccess(false)}>
        <Alert onClose={() => setRefreshSuccess(false)}
          severity="success"
          variant="filled"
          sx={{width: '100%'}}>
          Refresh complete.
        </Alert>
      </Snackbar>
      <Snackbar open={refreshError} autoHideDuration={1000} onClose={() => setRefreshError(false)}>
        <Alert onClose={() => setRefreshError(false)}
          severity="error"
          variant="filled"
          sx={{width: '100%'}}>
          Refresh failed.
        </Alert>
      </Snackbar>

      <Grid2 xs={12} md={6}><Typography variant="caption">
        You can search for IDs, names, emails and IPs.<br />
        Empty for currently online, 'new' for newest accounts (7 days), characters (3days) and guilds (30 days).
      </Typography></Grid2>
      <Grid2 xs={0} md={6} sx={{display: {xs: 'none', sm: 'none', md: 'block'}}} />
      <Grid2 xs={12} md={6}>
        <form onSubmit={e => {e.preventDefault(); search();}}>
          <Grid2 container spacing={2}>
            <Grid2 xs={9} md={10}>
              <TextField fullWidth label="Search" size="small"
                type="text" inputRef={searchField} />
            </Grid2>
            <Grid2 xs={3} md={2}>
              <Button type="submit" variant="contained" className="gridbutton"
                onClick={() => search()}><Search /></Button>
            </Grid2>
          </Grid2>
        </form>
      </Grid2>
      <Grid2 xs={0} md={5} sx={{display: {xs: 'none', sm: 'none', md: 'block'}}} />
      <Grid2 xs={12}><LinearProgress sx={{display: isLoading ? 'block' : 'none'}} /></Grid2>

      <Grid2 xs={12} />
      <Grid2 xs={12} />

      <Grid2 xs={12}>
        <Tabbed tabs={[
          {
            n: 'Accounts', e: (
              <Box className="section datagrid-container">
                <DataGrid getRowHeight={() => 'auto'} pageSizeOptions={[30, 50, 100]} rows={rowsAccounts}
                  initialState={{
                    pagination: {paginationModel: {pageSize: 100}},
                    sorting: {
                      sortModel: [{
                        field: 'id',
                        sort: location.state && location.state.pattern && location.state.pattern === 'new' ? 'desc' : 'asc'
                      }]
                    }
                  }}
                  columns={columnsAccounts} />
              </Box>
            )
          },
          {
            n: 'Characters', e: (
              <Box className="section datagrid-container">
                <DataGrid getRowHeight={() => 'auto'} pageSizeOptions={[30, 50, 100]} rows={rowsCharacters}
                  initialState={{
                    pagination: {paginationModel: {pageSize: 100}},
                    sorting: {
                      sortModel: [{
                        field: 'guid',
                        sort: location.state && location.state.pattern && location.state.pattern === 'new' ? 'desc' : 'asc'
                      }]
                    }
                  }}
                  columns={columnsCharacters} getRowId={row => row.guid} />
              </Box>
            )
          },
          {
            n: 'Guilds', e: (
              <Box className="section datagrid-container">
                <DataGrid getRowHeight={() => 'auto'} pageSizeOptions={[30, 50, 100]} rows={rowsGuilds}
                  initialState={{
                    pagination: {paginationModel: {pageSize: 100}},
                    sorting: {
                      sortModel: [{
                        field: 'guildid',
                        sort: location.state && location.state.pattern && location.state.pattern === 'new' ? 'desc' : 'asc'
                      }]
                    }
                  }}
                  columns={columnsGuilds} getRowId={row => row.guildid} />
              </Box>
            )
          }
        ]} />
      </Grid2>
    </Grid2>
  );
};

export default PageUsers;
