import {useContext, useEffect, useState} from 'react';
import {Alert, Box, Slider, Snackbar, Typography} from '@mui/material';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {ZULL_API} from 'zull-common-js';
import {GlobalContext} from '../helpers/globalContext';
import Tabbed from '../components/Tabbed';
import {RefreshContext} from '../helpers/refreshContext';

type TRowAPIError = {
  id: number,
  date: Date | string,
  status_code: number,
  message: string,
  request_url: string,
  post_json: string | null,
  accountid: number | null,
  remote_ip: string | null
}

const columns500: GridColDef[] = [
  {field: 'handled', headerName: '✔️', type: 'boolean', width: 50, editable: true},
  {field: 'date', headerName: 'Date', type: 'dateTime', width: 180},
  {field: 'message', headerName: 'Message', type: 'string', width: 280},
  {field: 'request_url', headerName: 'URL', type: 'string', width: 180},
  {field: 'accountid', headerName: 'AccID', type: 'number', width: 60},
  {field: 'remote_ip', headerName: 'IP', type: 'string', width: 120},
  {field: 'post_json', headerName: 'POST', type: 'string', width: 340}
];
const columns: GridColDef[] = [
  {field: 'date', headerName: 'Date', type: 'dateTime', width: 180},
  {field: 'status_code', headerName: 'Status', type: 'number', width: 60},
  {field: 'message', headerName: 'Message', type: 'string', width: 280},
  {field: 'request_url', headerName: 'URL', type: 'string', width: 180},
  {field: 'accountid', headerName: 'AccID', type: 'number', width: 60},
  {field: 'remote_ip', headerName: 'IP', type: 'string', width: 120},
  {field: 'post_json', headerName: 'POST', type: 'string', width: 340}
];

const PageLogsErrors = () => {
  const {globalState} = useContext(GlobalContext);
  const {setRefreshHandler} = useContext(RefreshContext);
  const [refreshSuccess, setRefreshSuccess] = useState(false);
  const [refreshError, setRefreshError] = useState(false);
  const [days, setDays] = useState(7);
  const [totalCount, setTotalCount] = useState(0);
  const [new500, setNew500] = useState(0);
  const [rows500, setRows500] = useState<(Omit<TRowAPIError, 'status_code'> & {handled: boolean})[]>([]);
  const [rows, setRows] = useState<TRowAPIError[]>([]);

  const getData = (daysToGet?: number) => {
    if (daysToGet === undefined) daysToGet = days;
    // 500
    ZULL_API.POST({
      endpoint: 'admin/log/errors500',
      authUser: globalState.username,
      authPass: globalState.password,
      body: JSON.stringify({days: daysToGet})
    }).then(res => {
      if (!res.ok || !res.body) {
        console.error(res);
        setRefreshError(true);
        return;
      }
      const ret = JSON.parse(res.body) as (Omit<TRowAPIError, 'status_code'> & {handled: boolean})[];
      for (let i = 0; i < ret.length; i++) ret[i].date = new Date(ret[i].date);
      setRows500(ret);
      setNew500(ret.reduce((n, x) => n + (x.handled ? 0 : 1), 0));
      // all errors
      return ZULL_API.POST({
        endpoint: 'admin/log/errors',
        authUser: globalState.username,
        authPass: globalState.password,
        body: JSON.stringify({days: daysToGet})
      });
    }).then(res => {
      if (!res || !res.ok || !res.body) {
        console.error(res);
        setRefreshError(true);
        return;
      }
      const ret = JSON.parse(res.body) as TRowAPIError[];
      for (let i = 0; i < ret.length; i++) ret[i].date = new Date(ret[i].date);
      setRows(ret);
      setTotalCount(ret.length);

      setRefreshSuccess(true);
    });
  };

  useEffect(() => {
    getData();
    setRefreshHandler(() => () => getData());
    return () => setRefreshHandler(null);
  }, []);

  return (
    <Grid2 container spacing={2}>
      <Snackbar open={refreshSuccess} autoHideDuration={1000} onClose={() => setRefreshSuccess(false)}>
        <Alert onClose={() => setRefreshSuccess(false)}
          severity="success"
          variant="filled"
          sx={{width: '100%'}}>
          Refresh complete.
        </Alert>
      </Snackbar>
      <Snackbar open={refreshError} autoHideDuration={1000} onClose={() => setRefreshError(false)}>
        <Alert onClose={() => setRefreshError(false)}
          severity="error"
          variant="filled"
          sx={{width: '100%'}}>
          Refresh failed.
        </Alert>
      </Snackbar>

      <Grid2 xs={8} md={2}><Typography variant="h3" align="right">{totalCount}</Typography></Grid2>
      <Grid2 xs={4} md={1}>IN LAST {days} DAYS</Grid2>
      <Grid2 xs={8} md={2}><Typography variant="h3" align="right">{new500}</Typography></Grid2>
      <Grid2 xs={4} md={1}>NEW 500 ERRORS</Grid2>
      <Grid2 xs={0} md={3} sx={{display: {xs: 'none', sm: 'none', md: 'block'}}} />
      <Grid2 xs={12} md={3}>
        <Box>
          <Typography>Days to show: {days}</Typography>
          <Slider step={null} marks={[{value: 1, label: '1'}, {value: 3, label: '3'}, {value: 7, label: '7'}, {value: 15, label: '15'}, {value: 30, label: '30'},]}
            min={1} max={30} value={days}
            onChange={(e, v) => setDays(typeof v === 'number' ? v : 1)}
            onChangeCommitted={(e, v) => getData(typeof v === 'number' ? v : 1)} />
        </Box>
      </Grid2>

      <Grid2 xs={12} />
      <Grid2 xs={12} />
      <Grid2 xs={12} />

      <Grid2 xs={12}>
        <Tabbed tabs={[
          {
            n: 'Errors - All', e: (
              <Box className="section datagrid-container">
                <DataGrid getRowHeight={() => 'auto'} pageSizeOptions={[30, 50, 100]} rows={rows}
                  columns={columns} initialState={{pagination: {paginationModel: {pageSize: 100}}, sorting: {sortModel: [{field: 'date', sort: 'desc'}]}}} />
              </Box>
            )
          },
          {
            n: 'Errors - 500', e: (
              <Box className="section datagrid-container">
                <DataGrid getRowHeight={() => 'auto'} pageSizeOptions={[30, 50, 100]} rows={rows500}
                  columns={columns500} initialState={{pagination: {paginationModel: {pageSize: 100}}, sorting: {sortModel: [{field: 'date', sort: 'desc'}]}}}
                  processRowUpdate={(updatedRow, originalRow) => {
                    if (JSON.stringify(updatedRow) !== JSON.stringify(originalRow)) ZULL_API.POST({
                      endpoint: 'admin/log/errorsethandled',
                      authUser: globalState.username,
                      authPass: globalState.password,
                      body: JSON.stringify({id: updatedRow.id, handled: updatedRow.handled})
                    }).then(res => {
                      if (res.ok) {
                        getData();
                      } else setRefreshError(true);
                    });
                    return updatedRow;
                  }} />
              </Box>
            )
          }
        ]} />
      </Grid2>
    </Grid2>
  );
};

export default PageLogsErrors;
