import {Dispatch, ReactNode, SetStateAction, createContext, useMemo, useState} from 'react';

export const RefreshContext = createContext<{refreshHandler: (() => void) | null, setRefreshHandler: Dispatch<SetStateAction<(() => void) | null>>}>(
  {refreshHandler: null as null, setRefreshHandler: () => {}}
);

const RefreshContextProvider = (props: {children: ReactNode}) => {
  const [refreshHandler, setRefreshHandler] = useState<(() => void) | null>(null);
  const providerValue = useMemo(() =>
  ({
    refreshHandler,
    setRefreshHandler
  }), [refreshHandler, setRefreshHandler]);

  return (
    <RefreshContext.Provider value={providerValue}>
      {props.children}
    </RefreshContext.Provider>
  );
};

export default RefreshContextProvider;
