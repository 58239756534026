import {createRoot} from 'react-dom/client';
import {CssBaseline, ThemeProvider, createTheme} from '@mui/material';
import {blueGrey, lightBlue} from '@mui/material/colors';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import GlobalContextProvider from './helpers/globalContext';
import RefreshContextProvider from './helpers/refreshContext';
import App from './App';
import './globals.css';

const root = createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: lightBlue,
    secondary: blueGrey
  }
});

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <GlobalContextProvider>
      <RefreshContextProvider>
        <App />
      </RefreshContextProvider>
    </GlobalContextProvider>
  </ThemeProvider>
);
