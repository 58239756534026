import {useContext, useEffect, useState} from 'react';
import {Alert, Box, CircularProgress, Snackbar, Typography} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import {ZULL_API} from 'zull-common-js';
import {GlobalContext} from '../helpers/globalContext';
import LineChart from '../components/ChartLine';
import {chartHeightHalf, chartHeightHero, chartWidthBar5, chartWidthFull} from '../components/ChartCommon';
import {RefreshContext} from '../helpers/refreshContext';
import BarChart from '../components/ChartBar';

type TStatsUser = {
  joinsPerDay: [{
    key: number,
    data: number
  }],
  onlineAccountsNow: number,
  totalAccounts: number,
  pendingRegistrations: number,
  howdidyoufindus: {
      answers30days: {
        answer: string | null,
        count: number
      }[],
      answers90days: {
        answer: string | null,
        count: number
      }[],
      answersTotal: {
        answer: string | null,
        count: number
      }[]
    }
}

const PageStatisticsUser = () => {
  const {globalState} = useContext(GlobalContext);
  const {setRefreshHandler} = useContext(RefreshContext);
  const [refreshSuccess, setRefreshSuccess] = useState(false);
  const [refreshError, setRefreshError] = useState(false);
  const [data, setData] = useState<TStatsUser | null>(null);

  const getData = () => {
    ZULL_API.GET({
      endpoint: 'admin/statistics/users',
      authUser: globalState.username,
      authPass: globalState.password
    }).then(res => {
      if (!res || !res.ok || !res.body) {
        console.error(res);
        setRefreshError(true);
        return;
      }
      setData(JSON.parse(res.body) as TStatsUser);
      setRefreshSuccess(true);
    });
  };

  useEffect(() => {
    getData();
    setRefreshHandler(() => getData);
    return () => setRefreshHandler(null);
  }, []);

  if (data === null) return (
    <CircularProgress />
  );

  return (
    <Grid2 container spacing={2}>
      <Snackbar open={refreshSuccess} autoHideDuration={1000} onClose={() => setRefreshSuccess(false)}>
        <Alert onClose={() => setRefreshSuccess(false)}
          severity="success"
          variant="filled"
          sx={{width: '100%'}}>
          Refresh complete.
        </Alert>
      </Snackbar>
      <Snackbar open={refreshError} autoHideDuration={1000} onClose={() => setRefreshError(false)}>
        <Alert onClose={() => setRefreshError(false)}
          severity="error"
          variant="filled"
          sx={{width: '100%'}}>
          Refresh failed.
        </Alert>
      </Snackbar>

      <Grid2 xs={0} md={1.5} sx={{display: {xs: 'none', sm: 'none', md: 'block'}}} />
      <Grid2 xs={8} md={2}><Typography variant="h3" align="right">{data.totalAccounts}</Typography></Grid2>
      <Grid2 xs={4} md={1}>TOTAL ACCOUNTS</Grid2>
      <Grid2 xs={8} md={2}><Typography variant="h3" align="right">{data.onlineAccountsNow}</Typography></Grid2>
      <Grid2 xs={4} md={1}>ONLINE NOW</Grid2>
      <Grid2 xs={8} md={2}><Typography variant="h3" align="right">{data.pendingRegistrations}</Typography></Grid2>
      <Grid2 xs={4} md={1}>PENDING ACCOUNTS</Grid2>
      <Grid2 xs={0} md={1.5} sx={{display: {xs: 'none', sm: 'none', md: 'block'}}} />

      <Grid2 xs={12} />
      <Grid2 xs={12} />
      <Grid2 xs={12} />

      <Grid2 xs={12}>
        <Box className="section" sx={{overflowX: 'auto', display: 'flex', alignItems: 'center', maxWidth: '1200px', margin: 'auto'}}>
          <LineChart data={data.joinsPerDay} animationIndex={1}
            height={chartHeightHero} minWidth={chartWidthFull} title="Account Creations Per Days-Ago"
            xLabel="...days ago" sumLabel="Total in last 30 days" />
        </Box>
      </Grid2>

      <Grid2 xs={12} md={4}>
        <Box className="section" sx={{overflowX: 'auto', display: 'flex', alignItems: 'center', maxWidth: '1200px', margin: 'auto'}}>
          <BarChart data={data.howdidyoufindus.answers30days.map(x => ({data: x.count, key: x.answer ?? 'Not answered'}))} animationIndex={2}
            height={chartHeightHalf} minWidth={chartWidthBar5} title="30 Days" />
        </Box>
      </Grid2>
      <Grid2 xs={12} md={4}>
        <Box className="section" sx={{overflowX: 'auto', display: 'flex', alignItems: 'center', maxWidth: '1200px', margin: 'auto'}}>
          <BarChart data={data.howdidyoufindus.answers90days.map(x => ({data: x.count, key: x.answer ?? 'Not answered'}))} animationIndex={2}
            height={chartHeightHalf} minWidth={chartWidthBar5} title="90 Days" />
        </Box>
      </Grid2>
      <Grid2 xs={12} md={4}>
        <Box className="section" sx={{overflowX: 'auto', display: 'flex', alignItems: 'center', maxWidth: '1200px', margin: 'auto'}}>
          <BarChart data={data.howdidyoufindus.answersTotal.map(x => ({data: x.count, key: x.answer ?? 'Not answered'}))} animationIndex={2}
            height={chartHeightHalf} minWidth={chartWidthBar5} title="Total" />
        </Box>
      </Grid2>
    </Grid2>
  );
};

export default PageStatisticsUser;
