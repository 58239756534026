import {Fragment, useContext} from 'react';
import {AutoGraph, BrokenImage, BugReport, Group, Home, Mail, Terminal, Storage, PersonSearch, HowToVote, Euro, PersonOff, Lock, ShoppingCart, Badge} from '@mui/icons-material';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import {GlobalContext} from './helpers/globalContext';
import LoginPage from './pages/Login';
import NavWrapper, {TRoute} from './NavWrapper';
import PageLogsErrors from './pages/PageLogsErrors';
import PageLogsMails from './pages/PageLogsMails';
import PageUsers from './pages/PageUsers';
import PageUsersAccount from './pages/PageUsersAccount';
import PageLogsCommands from './pages/PageLogsCommands';
import PageUsersCharacter from './pages/PageUsersCharacter';
import PageTickets from './pages/PageTickets';
import PageDashboard from './pages/PageDashboard';
import PageStatisticsUser from './pages/PageStatisticsUsers';
import PageStatisticsVotes from './pages/PageStatisticsVotes';
import PageStatisticsDonations from './pages/PageStatisticsDonations';
import PageUsersRestricted from './pages/PageUsersRestricted';
import PageLogsAdminAuth from './pages/PageLogsAdminAuth';
import PageLogsDonations from './pages/PageLogsDonations';
import PageLogsShop from './pages/PageLogsShop';
import PageUsersGuild from './pages/PageUsersGuild';
import PageUsersRenames from './pages/PageUsersRenames';

// sub-route are optional
// if sub-routes are given, element CAN be null
const routes: TRoute[] = [
  {
    name: 'Dashboard',
    icon: <Home />,
    element: <PageDashboard />
  },
  {
    name: 'Statistics',
    icon: <AutoGraph />,
    element: null,
    subRoutes: [
      {
        name: 'Donations',
        icon: <Euro />,
        element: <PageStatisticsDonations />
      },
      {
        name: 'Votes',
        icon: <HowToVote />,
        element: <PageStatisticsVotes />
      },
      {
        name: 'Accounts',
        icon: <Group />,
        element: <PageStatisticsUser />
      }
    ]
  },
  {
    name: 'Users',
    icon: <PersonSearch />,
    element: <PageUsers />,
    subRoutes: [
      {
        name: 'Restricted',
        icon: <PersonOff />,
        element: <PageUsersRestricted />,
        param: 'tab?'
      },
      {
        name: 'Renames',
        icon: <Badge />,
        element: <PageUsersRenames />
      }
    ]
  },
  {
    name: 'Tickets',
    icon: <BugReport />,
    element: <PageTickets />
  },
  {
    name: 'Logs',
    icon: <Storage />,
    element: null,
    subRoutes: [
      {
        name: 'Errors',
        icon: <BrokenImage />,
        element: <PageLogsErrors />
      },
      {
        name: 'Mails',
        icon: <Mail />,
        element: <PageLogsMails />
      },
      {
        name: 'Commands',
        icon: <Terminal />,
        element: <PageLogsCommands />
      },
      {
        name: 'Admin-Auth',
        icon: <Lock />,
        element: <PageLogsAdminAuth />
      },
      {
        name: 'Donations',
        icon: <Euro />,
        element: <PageLogsDonations />
      },
      {
        name: 'Shop',
        icon: <ShoppingCart />,
        element: <PageLogsShop />
      }
    ]
  }
];

const App = () => {
  const {globalState} = useContext(GlobalContext);

  if (!globalState.isLoggedIn) return (
    <LoginPage />
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<NavWrapper routes={routes} />}>
          {routes.map(x => {
            if (x.subRoutes !== undefined) return (
              <Fragment key={x.name}>
                <Route key={`route-${x.name}`} path={x.name} element={x.element} />
                {x.subRoutes.map(y => (
                  <Route key={`route-${x.name}-${y.name}`} path={`${x.name}/${y.name}${y.param ? `/:${y.param}` : ''}`} element={y.element} />
                ))}
              </Fragment>
            );
            return (<Route key={`route-${x.name}`} path={`${x.name}${x.param ? `/:${x.param}` : ''}`} element={x.element} />);
          })}

          <Route index element={<Navigate to={`/${routes[0].name}`} />} />

          <Route path="Users/Account/:id" element={<PageUsersAccount />} />
          <Route path="Users/Character/:id" element={<PageUsersCharacter />} />
          <Route path="Users/Guild/:id" element={<PageUsersGuild />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
