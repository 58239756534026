import {useContext, useEffect, useState} from 'react';
import {Alert, Box, CircularProgress, Snackbar, Typography} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import {ZULL_API} from 'zull-common-js';
import {GlobalContext} from '../helpers/globalContext';
import LineChart from '../components/ChartLine';
import {chartHeightHalf, chartWidthBar5, chartWidthFull} from '../components/ChartCommon';
import BarChart from '../components/ChartBar';
import {RefreshContext} from '../helpers/refreshContext';

type TStatsDonations = {
  totalDonations30days: number,
  totalDonations90days: number,
  totalDonations1year: number,
  totalDonationsTotal: number,
  totalThisMonth: number,
  totalThisYear: number,
  donationsPerMonthThisYear: {
    key: 'January' | 'February' | 'March' | 'April' | 'May' | 'June' | 'July' | 'August' | 'September' | 'October' | 'November' | 'December',
    data: number
  }[],
  donationsPerOption: {
    key: number,
    data: number
  }[]
  donationsPerDay30: {
    key: number,
    data: number
  }[]
  top90Days: {
    id: number,
    total: number,
    username: string
  }[],
  top1year: {
    id: number,
    total: number,
    username: string
  }[],
  topTotal: {
    id: number,
    total: number,
    username: string
  }[]
}

const PageStatisticsDonations = () => {
  const {globalState} = useContext(GlobalContext);
  const {setRefreshHandler} = useContext(RefreshContext);
  const [refreshSuccess, setRefreshSuccess] = useState(false);
  const [refreshError, setRefreshError] = useState(false);
  const [data, setData] = useState<TStatsDonations | null>(null);

  const getData = () => {
    ZULL_API.GET({
      endpoint: 'admin/statistics/donations',
      authUser: globalState.username,
      authPass: globalState.password
    }).then(res => {
      if (!res || !res.ok || !res.body) {
        console.error(res);
        setRefreshError(true);
        return;
      }
      setData(JSON.parse(res.body) as TStatsDonations);
      setRefreshSuccess(true);
    });
  };

  useEffect(() => {
    getData();
    setRefreshHandler(() => getData);
    return () => setRefreshHandler(null);
  }, []);

  if (data === null) return (
    <CircularProgress />
  );

  return (
    <Grid2 container spacing={2}>
      <Snackbar open={refreshSuccess} autoHideDuration={1000} onClose={() => setRefreshSuccess(false)}>
        <Alert onClose={() => setRefreshSuccess(false)}
          severity="success"
          variant="filled"
          sx={{width: '100%'}}>
          Refresh complete.
        </Alert>
      </Snackbar>
      <Snackbar open={refreshError} autoHideDuration={1000} onClose={() => setRefreshError(false)}>
        <Alert onClose={() => setRefreshError(false)}
          severity="error"
          variant="filled"
          sx={{width: '100%'}}>
          Refresh failed.
        </Alert>
      </Snackbar>

      <Grid2 xs={8} md={2}><Typography variant="h3" align="right">{data.totalDonations30days} €</Typography></Grid2>
      <Grid2 xs={4} md={1}>IN LAST 30 DAYS</Grid2>
      <Grid2 xs={8} md={2}><Typography variant="h3" align="right">{data.totalDonations90days} €</Typography></Grid2>
      <Grid2 xs={4} md={1}>IN LAST 90 DAYS</Grid2>
      <Grid2 xs={8} md={2}><Typography variant="h3" align="right">{data.totalDonations1year} €</Typography></Grid2>
      <Grid2 xs={4} md={1}>IN 1 YEAR (rolling)</Grid2>
      <Grid2 xs={8} md={2}><Typography variant="h3" align="right">{data.totalDonationsTotal} €</Typography></Grid2>
      <Grid2 xs={4} md={1}>IN<br />TOTAL</Grid2>

      <Grid2 xs={12} />
      <Grid2 xs={12} />
      <Grid2 xs={12} />

      <Grid2 xs={12}>
        <Box className="section" sx={{overflowX: 'auto', display: 'flex', alignItems: 'center', maxWidth: '1200px', margin: 'auto'}}>
          <BarChart data={data.donationsPerMonthThisYear} animationIndex={1} unit="€"
            height={chartHeightHalf} minWidth={chartWidthFull} title="Donation Sum Per Month - This Year"
            sumLabel="This year so far" />
        </Box>
      </Grid2>

      <Grid2 xs={12}>
        <Box className="section" sx={{overflowX: 'auto', display: 'flex', alignItems: 'center', maxWidth: '1200px', margin: 'auto'}}>
          <LineChart data={data.donationsPerDay30} animationIndex={2}
            height={chartHeightHalf} minWidth={chartWidthFull} title="Donation Sum Per Days-Ago"
            xLabel="...days ago" />
        </Box>
      </Grid2>

      <Grid2 xs={12} md={4}>
        <Box className="section" sx={{overflowX: 'auto', display: 'flex', alignItems: 'center', maxWidth: '1200px', margin: 'auto'}}>
          <BarChart data={data.top90Days.map(x => ({data: x.total, key: x.username, link: `/Users/Account/${x.id}`}))} animationIndex={3}
            height={chartHeightHalf} minWidth={chartWidthBar5}
            title="TOP: 90 Days" unit="€" />
        </Box>
      </Grid2>
      <Grid2 xs={12} md={4}>
        <Box className="section" sx={{overflowX: 'auto', display: 'flex', alignItems: 'center', maxWidth: '1200px', margin: 'auto'}}>
          <BarChart data={data.top1year.map(x => ({data: x.total, key: x.username, link: `/Users/Account/${x.id}`}))} animationIndex={3}
            height={chartHeightHalf} minWidth={chartWidthBar5}
            title="TOP: 1 Year (rolling)" unit="€" />
        </Box>
      </Grid2>
      <Grid2 xs={12} md={4}>
        <Box className="section" sx={{overflowX: 'auto', display: 'flex', alignItems: 'center', maxWidth: '1200px', margin: 'auto'}}>
          <BarChart data={data.topTotal.map(x => ({data: x.total, key: x.username, link: `/Users/Account/${x.id}`}))} animationIndex={3}
            height={chartHeightHalf} minWidth={chartWidthBar5}
            title="TOP: Total" unit="DP" />
        </Box>
      </Grid2>

      {/*

      UNDISPLAYED:
      totalThisMonth: number,
      totalThisYear: number,
      donationsPerOption: {
        key: number,
        data: number
      }[]

      */}
    </Grid2>
  );
};

export default PageStatisticsDonations;
