import {useContext, useRef, useState} from 'react';
import {Alert, Button, Card, Collapse, Container, Stack, TextField} from '@mui/material';
import {Login} from '@mui/icons-material';
import {ZULL_API} from 'zull-common-js';
import {GlobalContext} from '../helpers/globalContext';
import LogoLarge from '../assets/logo-large.png';

const LoginPage = () => {
  const {setGlobalState} = useContext(GlobalContext);
  const [error, setError] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const pwField = useRef<HTMLInputElement | null>(null);

  const login = () => {
    if (!username || !password) return;
    ZULL_API.GET({
      endpoint: 'admin/auth',
      authUser: username,
      authPass: password
    }).then(res => {
      if (res.ok) {
        setGlobalState(state => ({
          ...state,
          isLoggedIn: true,
          username: username,
          password: password
        }));
      } else {
        setError(true);
        setTimeout(() => setError(false), 3000);
        setGlobalState(state => ({
          ...state,
          isLoggedIn: false,
          username: '',
          password: ''
        }));
        setUsername('');
        setPassword('');
        (document.activeElement as HTMLElement).blur();
      }
    });
  };

  return (
    <Container maxWidth="sm" sx={{height: '100vh', minHeight: '100vh'}}>
      <Stack direction="column" spacing={2} sx={{alignItems: 'center', height: '100%'}}>
        <div className="flex-pad" />
        <img src={LogoLarge} alt="" style={{width: '420px', maxWidth: '100%'}} />
        <Card sx={{m: -2, p: 2}}>
          <Stack spacing={2}>
            <h1 style={{marginBottom: '1em'}}>WoWZull Admin Panel</h1>
            <Collapse in={error} sx={{marginBottom: '1em'}}>
              <Alert onClose={() => setError(false)}
                severity="error"
                variant="filled"
                sx={{width: '100%'}}>
                Computer sagt nein.
              </Alert>
            </Collapse>
            <TextField fullWidth label="Username" value={username}
              type="text" onChange={e => setUsername(e.target.value.trim())}
              onKeyUp={e => {if (e.key === 'Enter') pwField.current?.focus();}} />
            <TextField fullWidth label="Password" value={password}
              inputRef={pwField} type="password" onChange={e => setPassword(e.target.value.trim())}
              onKeyUp={e => {if (e.key === 'Enter') login();}} />
            <Button variant="contained" startIcon={<Login />} onClick={() => login()}>Login</Button>
          </Stack>
        </Card>
        <div className="flex-pad" />
      </Stack>
    </Container>
  );
};

export default LoginPage;
