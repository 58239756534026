import structuredClone from '@ungap/structured-clone';
import {CSSProperties, useContext, useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {Alert, Button, Checkbox, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, Stack, Tooltip} from '@mui/material';
import {BrokenImage, BugReport, Euro, Lock, Notifications, NotificationsOff, PersonOff, PlayArrow, VolumeOff, VolumeUp, Warning} from '@mui/icons-material';
import {ZULL_API, ZULL_DATETIME} from 'zull-common-js';
import {GlobalContext} from '../helpers/globalContext';

// eslint-disable-next-line no-var
var notificationPollingTimer: number | null = null;
// although un-elegant, this global variable (var keyword) is needed for the polling timer to keep its ID
// over re-renders and update cycles

type TNotificationData = {
  tickets: {
    name: string,
    description: string
  }[],
  errors: {
    status_code: number,
    message: string,
    request_url: string
  }[],
  errors500: {
    status_code: number,
    message: string,
    request_url: string
  }[],
  donations: {
    amount: number
  }[],
  bans: {
    accountid: number,
    bandate: number,
    unbandate: number,
    bannedby: string,
    banreason: string,
    username: string
  }[],
  adminauth: {
    username: string,
    additional_data: string | null
  }[]
}
const NotificationOptions: {option: keyof TNotificationData, name: string}[] = [
  {option: 'tickets', name: 'New open tickets'},
  {option: 'errors', name: 'New API Errors (not 500)'},
  {option: 'errors500', name: 'New API Errors (500 only)'},
  {option: 'donations', name: 'New Donations'},
  {option: 'bans', name: 'New Bans'},
  {option: 'adminauth', name: 'New failed admin login attempts'}
];
const NotificationSounds = ['beep', 'ring', 'kaching', 'bell', 'alarm_aggressive', 'alarm_soft', 'clown'] as const;
type TNotificationSettings = {
  enabled: boolean,
  options: Record<keyof TNotificationData, {enabled: boolean, sound: boolean, soundfile: typeof NotificationSounds[number]}>,
  /** Whether "past" (offline) notifications should be displayed on startup or should be ignored (reset to current state at startup time) */
  getPastOnStartup: boolean
};
const DefaultSettings: TNotificationSettings = {
  enabled: false,
  options: {
    tickets: {enabled: true, sound: true, soundfile: 'beep'},
    errors: {enabled: true, sound: false, soundfile: 'beep'},
    errors500: {enabled: true, sound: true, soundfile: 'beep'},
    donations: {enabled: false, sound: true, soundfile: 'kaching'},
    bans: {enabled: false, sound: false, soundfile: 'bell'},
    adminauth: {enabled: false, sound: false, soundfile: 'clown'},
  },
  getPastOnStartup: false
};

const emptyData: TNotificationData = {tickets: [], errors: [], errors500: [], donations: [], bans: [], adminauth: []};


interface INotificationHandlerProps {
  style?: CSSProperties
}

const NotificationHandler = (props: INotificationHandlerProps) => {
  const audio = new Audio();
  const {globalState} = useContext(GlobalContext);
  const [settings, setSettings] = useState<TNotificationSettings>(DefaultSettings);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [currentData, setCurrentData] = useState<TNotificationData>(emptyData);
  // This has to be a ref because currentData will be captured upon creation of the polling interval.
  // So if this was not a reference, currentData would ALWAYS be the default state and thus couldn't update based
  // on the previous state.
  const dataRef = useRef<TNotificationData | null>(null);
  dataRef.current = currentData;

  const playSound = (sound: typeof NotificationSounds[number]) => {
    const path = `${window.location.protocol}//${window.location.host}/static_assets/${sound}.mp3`;
    if (audio.src !== path) audio.src = path;
    audio.play();
  };

  const register = () => ZULL_API.GET({
    endpoint: 'admin/notifications/register',
    authUser: globalState.username,
    authPass: globalState.password
  });

  const poll = (oldData: TNotificationData | null) => {
    ZULL_API.GET({
      endpoint: 'admin/notifications/poll',
      authUser: globalState.username,
      authPass: globalState.password
    }).then(res => {
      if (!res || !res.ok || !res.body) {
        console.error(res);
        return;
      }
      const data = JSON.parse(res.body) as TNotificationData;
      let hasSoundPlayed: boolean = false;
      const newData = structuredClone(oldData ?? emptyData);

      Object.keys(data).forEach(x => {
        const key = x as keyof TNotificationData;
        if (data[key].length > 0 && settings.options[key].enabled) {
          // TypeScript currently has no method for dynamically accessing types via indexing, eg
          // ... as typeof newData[key][number] (which would have been an entry in whatever type of data the specific array holds)
          newData[key].push(...data[key] as any);
          if (!hasSoundPlayed && settings.options[key].sound) {
            hasSoundPlayed = true;
            playSound(settings.options[key].soundfile);
          }
        }
      });

      setCurrentData(newData);
    });
  };

  // TypeScript knows timers only as NodeJS.Timer, which is wrong in this case.
  // Vanilla JS timers (which react uses here) return their ID as number.
  const startPolling = () => {notificationPollingTimer = setInterval(() => poll(dataRef.current), 60 * 1000) as any;};
  const stopPolling = () => {if (notificationPollingTimer !== null) clearInterval(notificationPollingTimer);};

  const saveSettings = () => {
    setSettingsOpen(false);
    localStorage.setItem('notificationSettings', JSON.stringify(settings));
    if (settings.enabled && notificationPollingTimer === null) {
      register();
      startPolling();
    }
    if (!settings.enabled) stopPolling();
  };

  useEffect(() => {
    if (localStorage.getItem('notificationSettings')) {
      const read = JSON.parse(localStorage.getItem('notificationSettings') as string /* Type check in if */) as Partial<TNotificationSettings>;
      const sets = structuredClone(DefaultSettings);
      if (read.enabled !== undefined) sets.enabled = read.enabled;
      if (read.getPastOnStartup !== undefined) sets.getPastOnStartup = read.getPastOnStartup;
      if (read.options !== undefined) Object.keys(read.options).forEach(key => {
        sets.options[key as keyof TNotificationData] = (read.options as any /* Type check in if */)[key as keyof TNotificationData];
      });

      setSettings(sets);
      if (!sets.enabled) return;
      if (sets.getPastOnStartup) {
        poll(dataRef.current);
      } else register();
      startPolling();
    }
    return stopPolling;
  }, []);

  return (
    <>
      <Tooltip title={`Notifications are currently ${settings.enabled ? 'ON' : 'OFF'}, click to customize`}>
        <IconButton style={props.style} onClick={() => setSettingsOpen(true)}>
          {settings.enabled ? <Notifications /> : <NotificationsOff />}
        </IconButton>
      </Tooltip>

      <Dialog open={settingsOpen} onClose={saveSettings} fullWidth>
        <DialogTitle>Notification Settings</DialogTitle>
        <DialogContent>
          <List>
            <ListItem>
              <ListItemButton role="button" onClick={() => setSettings({...settings, enabled: !settings.enabled})}>
                <ListItemIcon><Checkbox edge="start" checked={settings.enabled} /></ListItemIcon>
                <ListItemText>Notifications enabled</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton role="button" onClick={() => setSettings({...settings, getPastOnStartup: !settings.getPastOnStartup})}>
                <ListItemIcon><Checkbox edge="start" checked={settings.getPastOnStartup} /></ListItemIcon>
                <ListItemText>Display missed notifications (happened while logged out / offline, eg. while this ACP wasn't open) on login</ListItemText>
              </ListItemButton>
            </ListItem>
            <Divider sx={{marginTop: 1, marginBottom: 1}} />

            <strong>Notification Types</strong>
            <ListItem><List sx={{width: '100%'}}>
              {NotificationOptions.map(x => (
                <ListItem key={x.option} sx={{display: 'block'}}
                  disablePadding secondaryAction={
                    <Tooltip title={`Sound is currently ${settings.options[x.option].sound ? 'ON' : 'OFF'}`}>
                      <IconButton edge="end" disabled={!settings.enabled} onClick={() => {
                        const sets = {...settings};
                        sets.options[x.option].sound = !sets.options[x.option].sound;
                        setSettings(sets);
                      }}>
                        {settings.options[x.option].sound ? <VolumeUp /> : <VolumeOff />}
                      </IconButton>
                    </Tooltip>
                  }>
                  <ListItemButton role="button" dense disabled={!settings.enabled}
                    onClick={() => {
                      const sets = {...settings};
                      sets.options[x.option].enabled = !sets.options[x.option].enabled;
                      setSettings(sets);
                    }}>
                    <ListItemIcon><Checkbox edge="start" checked={settings.options[x.option].enabled} /></ListItemIcon>
                    <ListItemText>{x.name}</ListItemText>
                  </ListItemButton>
                  <Collapse in={settings.options[x.option].enabled && settings.options[x.option].sound}>
                    <Stack direction="row" gap={2} sx={{marginRight: 8}}
                      useFlexGap>
                      <span style={{alignSelf: 'center'}}>Sound:</span>
                      <Select value={settings.options[x.option].soundfile} fullWidth size="small"
                        disabled={!settings.enabled} onChange={e => {
                          const sets = {...settings};
                          sets.options[x.option].soundfile = e.target.value as typeof NotificationSounds[number];
                          setSettings(sets);
                        }}>
                        {NotificationSounds.map(s => <MenuItem key={s} value={s}>{s}</MenuItem>)}
                      </Select>
                      <IconButton disabled={!settings.enabled} onClick={() => playSound(settings.options[x.option].soundfile)}>
                        <PlayArrow />
                      </IconButton>
                    </Stack>
                  </Collapse>
                  <Divider sx={{marginTop: 1}} />
                </ListItem>
              ))}
            </List></ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={saveSettings}>Save</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={Object.values(currentData).some(x => x.length > 0)}
        onClose={(e, r) => {if (r === 'escapeKeyDown') setCurrentData(structuredClone(emptyData));}} fullWidth>
        <DialogTitle><Notifications /></DialogTitle>
        <DialogContent>
          <Stack direction="column" useFlexGap spacing={1}>
            {currentData.tickets.length > 0 && <Stack direction="column">
              {currentData.tickets.map((x, i) => (
                <Alert key={`ticket-${i}`} severity="warning" icon={<BugReport fontSize="inherit" />}>
                  <strong>Ticket by {x.name}:</strong> {x.description}
                </Alert>
              ))}
            </Stack>}
            {currentData.errors.length > 0 && <Stack direction="column">
              {currentData.errors.map((x, i) => (
                <Alert key={`error-${i}`} severity="error" icon={<Warning fontSize="inherit" />}>
                  <strong>{x.status_code}:</strong> {x.request_url}: {x.message}
                </Alert>
              ))}
            </Stack>}
            {currentData.errors500.length > 0 && <Stack direction="column">
              {currentData.errors500.map((x, i) => (
                <Alert key={`error500-${i}`} severity="error" icon={<BrokenImage fontSize="inherit" />}>
                  <strong>{x.status_code}:</strong> {x.request_url}: {x.message}
                </Alert>
              ))}
            </Stack>}
            {currentData.donations.length > 0 && <Stack direction="column">
              {currentData.donations.map((x, i) => (
                <Alert key={`donation-${i}`} severity="info" icon={<Euro fontSize="inherit" />}>
                  <strong>{x.amount}€</strong> Donation!
                </Alert>
              ))}
            </Stack>}
            {currentData.bans.length > 0 && <Stack direction="column">
              {currentData.bans.map((x, i) => (
                <Alert key={`ban-${i}`} severity="warning" icon={<PersonOff fontSize="inherit" />}>
                  <strong><Link to={`/Users/Account/${x.accountid}`}>{x.username}</Link> banned</strong> by {x.bannedby} for {x.banreason}
                  {x.unbandate < x.bandate ? ' PERMANENTLY' : ` until ${ZULL_DATETIME.timestampFormatIso(new Date(x.unbandate * 1000), ZULL_DATETIME.TimestampFormat.yyyymmddhhmmss)}`}
                </Alert>
              ))}
            </Stack>}
            {currentData.adminauth.length > 0 && <Stack direction="column">
              {currentData.adminauth.map((x, i) => (
                <Alert key={`adminauth-${i}`} severity="error" icon={<Lock fontSize="inherit" />}>
                  <strong>Failed admin login:</strong> account {x.username}: {x.additional_data}
                </Alert>
              ))}
            </Stack>}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCurrentData(structuredClone(emptyData))}>Dismiss</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NotificationHandler;
